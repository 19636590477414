
.entity-preview {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  &.icon {
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: 76px;
    height: 76px;
  }
}
