@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';
@import 'styles/colors.module';


.creative-storage-asset-actions {
  margin-bottom: -6px;
  height: 32px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid $solid-light-grey;;

  &__approve {
    @include flex-centering;
    width: 104px;
    height: 32px;
    background-color: $extra-light-grey;
  }

  &__approve-text {
    @include text-primary(10px);
    margin-right: 5px;
  }

  &__approve-icon.MuiSvgIcon-root {
    @include clickable-element;
    color: $pink;
    font-size: 16px;

    &:hover {
      color: $blue;
    }

    &:first-of-type {
      margin-right: 2px;
    }
  }

  &__cant-approve-text {
    margin: 14px;
    font-size: 14px;
    font-family: $font-main-regular;
    text-align: center;
    color: $solid-dark-blue;
    word-break: break-word;
  }
}
