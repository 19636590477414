@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.message-modal {
  position: relative;
  width: 361px;
  min-height: 52px;
  padding: 12px 19px;
  background-color: $success;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 8px;
  justify-content: center;
  align-items: center;
  animation: 300ms linear slide_from_right;
  border-radius: 6px;

  &.fixed {
    position: fixed;
    top: 36px;
    right: 16px;
    z-index: 5;
  }

  &.hide {
    animation: 300ms linear forwards slide_to_right;
  }

  &.error {
    background-color: $error;
  }

  &__success-icon.MuiSvgIcon-root,
  &__error-icon.MuiSvgIcon-root {
    color: $white;
    font-size: 32px;
  }

  &__text {
    color: $white;
    white-space: pre-line;
  }

  &__header {
    margin: 0;
    font-size: 12px;
    font-family: $font-main-bold;
    text-transform: uppercase;
  }

  &__subheader {
    margin: 0;
    font-size: 10px;
    font-family: $font-main-regular;
  }

  &__close-icon.MuiSvgIcon-root {
    @include clickable-element;
    color: $white;
    font-size: 24px;

    &.disable {
      display: none;
    }
  }
}

@keyframes slide_from_right {
  from {
    transform: translateX(100%);
    opacity: 0;
    visibility: hidden;
  }
  to {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes slide_to_right {
  from {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
    visibility: hidden;
  }
}

