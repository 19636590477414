@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.collections-modal {

  &__content {
    margin-bottom: 32px;
    padding: 24px 16px;
    width: 426px;
    min-height: 253px;
    background-color: $whitePurple;

    &.loading {
      @include flex-centering;
    }

    &.createOrEditState {
      display: grid;
      grid-template-rows: repeat(3, auto);
      grid-row-gap: 24px;
    }
  }

  &__create-button-container {
    display: grid;
    grid-template-rows: auto auto;
    justify-content: center;
    align-content: center;
    grid-row-gap: 24px;
    width: 100%;
    height: 173px;

    .collections-modal-separator {
      @include flex-centering;
      gap: 16px;

      &__line {
        width: 16px;
        height: 1px;
        background-color: $placeholder-color-light;
      }

      &__text {
        font-size: 12px;
        font-family: $font-main-regular;
        text-transform: uppercase;
        color: $placeholder-color-light;
      }
    }
  }

  &__actions-buttons-container {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: center;
    grid-gap: 16px;
  }

  &__create-button {
    @include oval-button-secondary(false, 190px, 40px, true);
  }

  &__cancel-button {
    @include oval-button-primary(true, 130px, 40px, true);
  }

  &__proceed-button {
    @include oval-button-primary(false, 130px, 40px, true);
  }
}