@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.folder-list-item {
  $this: &;
  min-width: 900px;
  height: 64px;
  padding: 0 24px;
  display: grid;
  grid-template-columns: minmax(32px, 0.32fr) minmax(68px, 0.68fr) minmax(47px, 0.47fr) minmax(293px, 2.93fr) minmax(97px, 0.97fr) minmax(148px, 1.48fr) minmax(71px, 0.71fr);
  align-items: center;
  grid-column-gap: 16px;
  background: $white;

  &.collection {
    grid-template-columns: minmax(32px, 0.32fr) minmax(68px, 0.68fr) minmax(47px, 0.47fr) minmax(293px, 2.93fr) minmax(106px, 1.06fr) minmax(333px, 3.33fr) minmax(374px, 3.74fr) minmax(148px, 1.48fr) minmax(71px, 0.71fr);
  }

  &.drop {
    background-color: $whitePurple;
  }

  .custom-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;

    &__icon {
      width: 18px;
      height: 18px;
      border: 2px solid $brightBlue;

      &.active {
        background: $brightBlue;

        path {
          stroke: $white;
        }
      }
    }
  }

  &__preview {
    @include flex-centering;
    @include clickable-element;
    position: relative;
    width: 50px;
    height: 50px;
    overflow: hidden;

    &:hover {
      .entity-overlay {
        display: block;
      }
    }
  }

  &__icon {
    width: 32px;
    height: 32px;

    path {
      fill: $pink;
    }
  }

  .entity-status {
    justify-self: start;
    margin-left: 12px;
  }

  &__name {
    @include text-primary;
  }

  &__extension,
  &__creation-date {
    @include text-primary;
    font-family: $font-main-regular;
  }
}