@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.collection-storage-list-view {
  display: grid;
  grid-template-columns: 1fr;

  &__title {
    @include text-primary;
  }
}

.collection-storage-list-view-table {
  @include sсroll-style(5px, true);
  flex-direction: column;
  overflow-x: auto;
  overflow-y: hidden;

  &__header {
    margin-top: 22px;
    min-width: min-content;
    height: 34px;
    padding: 0 24px;
    display: grid;
    grid-template-columns: minmax(32px, 0.32fr) minmax(68px, 0.68fr) minmax(47px, 0.47fr) minmax(293px, 2.93fr) minmax(106px, 1.06fr) minmax(333px, 3.33fr) minmax(374px, 3.74fr) minmax(148px, 1.48fr) minmax(71px, 0.71fr);
    align-items: center;
    grid-column-gap: 16px;
    background-color: transparentize($brightBlue, 0.9);
  }

  &__title {
    margin: 0;
    font-size: 12px;
    font-family: $font-main-semiBold;
    color: $brightBlue;
    line-height: 14px;
    text-transform: uppercase;
  }
}