@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';
@import 'styles/colors.module';


.round-modal-with-input {

  &__input-holder {
    margin-bottom: 32px;
    width: 426px;
    min-height: 93px;
    padding: 24px 16px;
    background-color: $whitePurple;
    border-radius: 8px;

    &.loading {
      @include flex-centering;
      padding: 0;
    }
  }

  &__buttons-container {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: center;
    grid-gap: 16px;
  }

  &__button {
    @include oval-button-primary(true, 130px, 40px, true);

    &:last-child {
      @include oval-button-primary(false, 130px, 40px, true);
    }
  }
}
