@import '../../styles/colors.module';
@import '../../assets/styles/variables.module';
@import '../../assets/styles/mixins';


.history {
  width: 100%;
  height: 100%;
  display: grid;
  align-content: space-between;

  .history-without-table-state {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 574px;

    .history-empty-state {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .MuiSvgIcon-root {
        color: $darkGrey;
        font-size: 50px;
      }

      &__text {
        color: $darkGrey;
        font-size: 14px;
        font-family: $font-main-regular;
        letter-spacing: 0.3px;
      }
    }
  }

  .history-table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid $solid-light-grey;
    margin-bottom: 12px;

    &__header {
      background: $solid-light-grey;
    }

    &__header-cell {
      height: 40px;
      color: $darkGrey;
      font-size: 10px;
      font-family: $font-main-bold;
      text-transform: uppercase;
      text-align: left;
      padding: 0 16px;

      &--time {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 12px;
      }

      &:nth-child(1) {
        width: 40%;
      }

      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(3) {
        width: 20%;
      }

      &:nth-child(4) {
        width: 20%;
      }
    }

    &__sort-icons-container {
      @include clickable-element;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__sort-icon.MuiSvgIcon-root {
      color: $placeholder-color;
      font-size: 15px;

      &.active {
        color: $solid-blue;
      }

      &:last-child {
        margin-top: -8px;
      }
    }

    &__body-cell {
      padding: 16px;
      color: $darkGrey;
      font-size: 10px;
      font-family: $font-main-bold;
      text-transform: uppercase;
      border: 1px solid $solid-light-grey;
      word-break: break-all;
    }
  }
}
