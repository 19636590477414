@import '../../../styles/colors.module.scss';

:local(.popper) {
  $itemBorder: 1px solid $lightGrey;

  z-index: 1301;

  :global(.MuiAutocomplete-root) {
    border: $itemBorder;

    :global(.MuiInput-root) {
      padding: 1em 1.25em;
      font-size: 0.875rem;
      line-height: 1.75;

      :local(.searchIcon) {
        width: 1em;
        height: 1em;
      }
    }
  }

  :global(.MuiAutocomplete-popper) {
    width: 100% !important;

    :global(.MuiAutocomplete-listbox) li {
      justify-content: space-between;

      &:hover {
        :local(.closeIcon) path {
          fill: white;
        }
      }

      :local(.closeIcon) {
        width: 0.75em;
        height: 0.75em;
      }
    }
  }
}

:local(.tagList) {
  $itemBorder: 1px solid $lightGrey;

  white-space: nowrap;
  overflow-x: auto;

  > :not(:last-child) {
    margin-right: 2px;
  }

  :local(.addNewButton) {
    white-space: nowrap;

    &:hover {
      background-color: $blue;
      color: white;

      :local(.plusIcon) path {
        fill: white;
      }
    }

    :local(.plusIcon) {
      margin-right: 0.5ch;
      width: 1em;
      height: 1em;
    }
  }
}
