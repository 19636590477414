:local(.approvePopup) {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin-top: 0;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }

  :local(.approveControls) {
    display: flex;

    :global(.MuiButton-root):not(:last-child) {
      margin-right: 1rem;
    }
  }
}
