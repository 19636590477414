@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.asset-uploader-form {
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 32px;

  &.loading {
    width: 1004px;
    height: 464px;
    grid-template-rows: auto;
  }

  &__main {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 11px;
  }

  &__buttons-container {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    grid-column-gap: 16px;
  }

  &__button {
    @include oval-button-primary(true, 130px, 40px, true);

    &:last-child {
      @include oval-button-primary(false, 130px, 40px, true);
    }
  }

  &__naming-convention-list {
    @include sсroll-style(5px, true);
    overflow-x: hidden;
    overflow-y: auto;
    margin: -6px 0 32px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    max-height: 52vh;
    padding-right: 10px;
  }

  &__naming-convention-list-item,
  &__duplicate-names {
    font-size: 14px;
    font-family: $font-main-bold;
    color: $solid-dark-grey;
    text-align: center;
    line-height: 20px;
    word-break: break-word;
  }
}
