@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.collection-info {
  min-height: calc(100vh - 164px);
  background-color: $whitePurple;

  &__title {
    @include text-primary(14px);
    display: flex;
    align-items: center;
    height: 72px;
    padding: 0 32px;
    border-bottom: 1px solid $white;
  }

  &__content {
    padding: 24px 32px;
  }
}

.collection-info-section {
  @include text-primary(10px);

  &__title {
    margin: 0;
  }

  &__no-data {
    margin: 9px 16px;
    font-family: $font-main-regular;
    color: $placeholder-color-light;
  }

  &__list {
    margin: 9px 0 16px;
    padding-left: 16px;
    font-family: $font-main-semiBold;
  }

  &__list-item {

    &::marker {
      color: $pink;
      font-size: 14px;
    }

    &.regular {
      font-family: $font-main-regular;
      text-overflow: initial;
      overflow: initial;
      word-break: break-all;
      white-space: normal;
      
      &::marker {
        font-size: 11px;
      }
    }
  }
}