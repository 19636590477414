
.lab-header {
  margin: 0;

  &__main {
    font-size: 16px;
  }

  &__large {
    font-size: 14px;
  }

  &__medium {
    font-size: 12px;
  }

  &__small {
    font-size: 10px;
  }

  &__tiny {
    font-size: 8px;
  }
}
