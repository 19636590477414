@import 'assets/styles/mixins';


:local(.videoPlayOverlay) {
  @include flex-centering;
  width: 100%;
  height: 100%;

  :global(.MuiIconButton-root) {
    @include flex-centering;
    width: 46px;
    height: 46px;
    border: 2px solid white;
  }

  :global(.MuiIconButton-label) {
    margin-left: 2px;
  }

  :global(.MuiSvgIcon-root) {
    fill: white;
    font-size: 35px;
  }
}
