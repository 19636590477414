@import '../../../styles/colors.module.scss';

:local(.container) {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :local(.errorCode) {
    margin-bottom: 2rem;
    font-size: 10rem;
    line-height: 1;
    color: $pink;
  }

  :local(.icon) {
    width: 130px;
    height: 127px;
  }

  :global(.MuiTypography-h1) {
    margin-bottom: 2rem;
  }

  :local(.controls) {
    > :not(:last-child) {
      margin-right: 2rem;
    }

    a {
      text-decoration: none;
    }
  }
}
