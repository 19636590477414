@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';

.error-fallback {
  @include flex-centering;
  position: fixed;
  width: 100%;
  height: 100%;
  flex-direction: column;


  &__icon {
    width: 200px;
    height: 200px;
    path, ellipse  {
      fill: $error;
    }
  }

  &__title,
  &__message {
    margin: 16px;
    font-size: 24px;
    font-family: $font-main-bold;
    text-align: center;
    color: $solid-dark-grey
  }

  &__message {
    @include text-ellipsis(15);
    font-size: 18px;
    color: $solid-dark-blue;
  }

  &__buttons {
    @include flex-centering();
    column-gap: 16px;
  }

  &__button {
    @include oval-button-primary(true);
    margin-top: 24px;

    &:last-child {
      @include oval-button-primary();
    }
  }
}
