@import 'styles/gradients.module';
@import 'assets/styles/mixins';


:local(.container) {
  @include flex-centering;
  width: 100vw;
  height: 100vh;
  background-image: url('../../../assets/images/login-background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;

  :local(.loginContainer) {
    @include flex-centering;
    $borderWidth: 0.625rem;

    width: 640px;
    padding: 2.5rem;
    flex-direction: column;
    border-top: $borderWidth solid;
    border-bottom: $borderWidth solid;
    border-image: $blueToPink 1;

    :local(.tiltingPointLogo),
    :local(.jiraLogo) {
      width: auto;
    }

    :local(.tiltingPointLogo) {
      height: 3.5rem;
      margin-bottom: 2.4rem;
    }

    :global(.MuiTypography-h1) {
      margin-bottom: 2.4rem;
    }

    :local(.controls) {
      display: flex;
      align-items: flex-end;
      justify-content: center;

      :local(.jiraLogo) {
        height: 3rem;
        margin-right: 1.5rem;
      }
    }
  }
}
