@import 'assets/styles/variables.module';
@import 'styles/colors.module';


.header-with-line {
  padding-bottom: 7px;
  border-bottom: 1px solid $solid-light-grey;

  &__text {
    margin: 0;
    font-size: 16px;
    font-family: $font-main-bold;
    color: $solid-pink;
    text-transform: uppercase;
  }

  &__notes {
    margin: 0;
    font-size: 10px;
    font-family: $font-main-regular;
    color: $darkGrey;
    padding-top: 6px;
  }
}
