@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.creative-general-info-form {
  &__main {
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    justify-content: space-between;
    align-items: start;
    grid-gap: 32px;
  }

  &__input.MuiFormControl-root {
    display: flex;

    .MuiInputBase-root {
      padding: 0 16px;
      width: 100%;
      height: 30px;
      font-size: 10px;
      font-family: $font-main-bold;
      border: 1px solid $solid-light-grey;

      &.Mui-error {
        border: 1px solid $solid-errors;
      }
    }

    .MuiInputAdornment-root {
      font-size: 10px;
      text-transform: uppercase;
      color: $placeholder-color;
      margin-right: 3px;

      .MuiTypography-colorTextSecondary {
        margin-top: -1px;
        font-size: 10px;
        font-family: $font-main-bold;
        color: $placeholder-color;
      }
    }

     .MuiInputBase-input {
       text-transform: uppercase;
       color: $darkGrey;
    }

    .MuiFormHelperText-root.Mui-error {
      color: $solid-errors;
      padding: 0 16px;
      font-size: 10px;
      font-family: $font-main-regular;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__buttons-container {
    margin-top: 16px;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: right;
    align-items: center;
    grid-gap: 8px;
  }

  &__button {
    @include rectangle-button(false, 111px, null, true);

    &:last-child {
      @include rectangle-button(true, 111px, null, true);
    }
  }
}
