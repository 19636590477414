@import 'styles/colors.module';
@import 'assets/styles/mixins';


.sorting-button {

  .button-with-dropDown {
    &__trigger {
      width: 104px;
      height: 32px;
      grid-column-gap: 8px;
      background-color: $extra-light-grey;
    }

    &__name {
      font-size: 10px;
      color: $solid-dark-grey;
    }

    &__content {
      top: calc(100% + 1px);
      border: none;
    }
  }

  &__dropDown {
    display: grid;
    grid-template-rows: repeat(4, auto);
    padding: 8px 0;
  }

  &__sort-option {
    @include text-primary(10px);
    @include clickable-element;
    display: flex;
    align-items: center;
    height: 34px;
    padding: 0 16px;

    &.active {
      color: $brightBlue;
    }

    &:hover {
      color: $pink;
    }
  }
}