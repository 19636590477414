@import 'styles/colors.module';
@import 'assets/styles/variables.module';


.collections-navigate-back {
  padding: 28px 37px;

  &.notVisible {
    visibility: hidden;
  }

  &__link {
    width: 130px;
    display: flex;
    align-items: center;
    gap: 13px;
    text-decoration: none;
  }

  &__icon.MuiSvgIcon-root {
    color: $white;
    font-size: 32px;
  }

  &__text {
    font-size: 24px;
    font-family: $font-main-bold;
    text-transform: uppercase;
    color: $white;
  }
}