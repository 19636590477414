@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.search.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.search__container {
  position: relative;
  width: 100%;
  height: 40px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  grid-gap: 5px;
  align-items: center;
  padding: 0 16px;
  background-color: $white;
  border-top: 1px solid $solid-light-grey;

  &.withClear {
    grid-template-columns: auto 1fr auto;
  }

  &.pink {
    height: 34px;
    border: none;

    .search__icon.MuiSvgIcon-root {
      font-size: 18px;
      color: $pink;

      &:last-child {
        font-size: 16px;
        color: $pink;
      }
    }

    .search__field {
      height: 30px;
      color: $solid-dark-grey;

      &::placeholder {
        color: $placeholder-color-light;
      }
    }
  }
}

.search__icon.MuiSvgIcon-root {
  font-size: 24px;
  color: $placeholder-color;
  margin: 0;

  &:last-child {
    @include clickable-element;
    color: $darkGrey;
  }
}

.search__field {
  border: none;
  outline: none;
  font-size: 10px;
  color: $darkGrey;
  font-family: $font-main-bold;
  height: 38px;
  margin: 0;
  padding: 0;

  &::placeholder {
    text-transform: uppercase;
    font-size: 10px;
    font-family: $font-main-bold;
    color: $placeholder-color;
    letter-spacing: 0.5px;
  }
}