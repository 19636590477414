@import 'styles/colors.module';
@import 'styles/gradients.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.folder-grid-item {
  position: relative;
  width: 213px;
  height: 48px;
  padding: 0 18px;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-column-gap: 10.5px;
  background-color: $extra-light-grey;
  border: 1px solid transparent;
  border-radius: 8px;
  overflow: hidden;

  &:hover {
    .entity-overlay,
    .custom-checkbox {
      display: block;
    }
  }

  &.error {
    border-color: $error;
  }

  &.drop {
    background-color: $brightBlue;
  }

  &__icon {
    width: 24px;
    height: 24px;

    path {
      fill: $pink;
    }
  }

  &__name {
    @include text-primary;
  }

  .custom-checkbox {
    display: none;
    position: absolute;
    top: 15px;
    right: 11px;

    &__icon {
      width: 18px;
      height: 18px;
    }

    &.active {
      display: block;
    }
  }
}