@import 'styles/colors.module';
@import 'styles/gradients.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.uploader-asset-card {
  $this: &;
  position: relative;
  width: 150px;
  height: 150px;
  display: grid;
  grid-template-rows: 113px 1fr;
  grid-template-columns: 150px;
  background-color: $extra-light-grey;
  border: 1px solid $extra-light-grey;
  border-radius: 8px;
  transition: all 300ms linear;
  overflow: hidden;

  &:hover,
  &.isSelected {
    #{$this}__overlay {
      visibility: visible;
      opacity: 1;
    }
  }

  &.error {
    border: 1px solid $error;
  }

  &__overlay {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    padding: 9px;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    justify-content: space-between;
    align-content: space-between;
    align-items: start;
    background: $blueToLightBlueWithTransparency;
    border-radius: 8px;
    visibility: hidden;
    opacity: 0;
    transition: all 300ms;
    z-index: 5;
  }

  &__close-button {
    @include clickable-element;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid $white;
    border-radius: 8px;

    &:hover {
      background-color: $solid-black-28;
    }
  }

  &__close-icon.MuiSvgIcon-root {
    color: $white;
    font-size: 13px;
  }

  .custom-checkbox {
    margin: 3px;

    &__icon {
      width: 18px;
      height: 18px;

      &.active path {
        stroke: transparentize($neonBlue, 0.5);
      }
    }
  }

  &__tooltip {
    grid-column: 1 / 3;
    width: 100%;
    height: 20px;
  }

  &__preview-container {
    @include flex-centering;
  }

  &__image,
  &__video {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &.icon {
      width: 52px;
      height: 52px;
    }
  }

  &__name {
    @include text-primary(10px);
    padding: 12px;
    border-top: 1px solid $placeholder-color-lighter;
  }
}
