@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';
@import 'styles/colors.module';


.grid-list-view-panel {
  $this: &;
  width: 110px;
  height: 30px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  grid-column-gap: 6px;
  border: 1px solid $solid-light-grey;

  &.newDesign {
    width: 104px;
    height: 32px;
    grid-column-gap: 10px;
    background-color: $extra-light-grey;

    #{$this}__name {
      color: $solid-dark-grey;
    }

    #{$this}__icon {
      color: $solid-dark-grey;

      path {
        fill: $solid-dark-grey;
      }

      &.active {
        color: $brightBlue;

        path {
          fill: $brightBlue;
        }
      }
    }

    #{$this}__options {
      gap: 6px;
    }
  }

  &__name {
    font-size: 10px;
    font-family: $font-main-bold;
    text-transform: uppercase;
    color: $darkGrey;
  }

  &__options {
    display: flex;
    align-items: center;
    gap: 3px;
  }

  &__icon {
    @include clickable-element;

    &.MuiSvgIcon-root {
      font-size: 16px;
    }

    path {
      fill: $darkGrey;
    }

    &.active {
      path {
        fill: $solid-blue;
      }
    }
  }
}