@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.file-grid-item {
  $this: &;
  position: relative;
  width: 213px;
  height: 234px;
  display: grid;
  grid-template-columns: 213px;
  grid-template-rows: 151px 83px;
  background-color: $extra-light-grey;
  border: 1px solid transparent;
  border-radius: 8px;
  overflow: hidden;

  &:hover {
    .entity-overlay {
      display: block;
    }

    #{$this}__info-icon-container {
      display: flex;
    }
  }

  &.error {
    border-color: $error;
  }

  &__preview {
    position: relative;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid $placeholder-color-light;
  }

  &__video-play-icon {
    position: absolute;
  }

  &__info-icon-container {
    position: absolute;
    top: 56px;
    left: 50%;
    display: none;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    border: 2px solid $white;
    translate: -50%;
    cursor: pointer;
    border-radius: 10px;
    transition: background 200ms;

    &:hover {
      background: transparentize($solid-black, 0.72);
    }
  }

  &__info-icon.MuiSvgIcon-root {
    color: $white;
    font-size: 22px;
  }

  &__badges-container {
    position: absolute;
    inset: 0;
    padding: 8px;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    z-index: 1;
  }

  &__badge {
    @include flex-centering;
    max-width: 100px;
    height: 21px;
    padding: 0 8px;
    border-radius: 20px;
    background-color: $pink;

    &.hidden {
      visibility: hidden;
    }

    &.extension {
      background-color: $brightBlue;
    }
  }

  &__badge-text {
    @include text-primary;
    color: $white;
  }

  .custom-checkbox {
    position: absolute;
    top: 11px;
    right: 11px;

    &__icon {
      width: 18px;
      height: 18px;
    }

    &.active {
      display: block;
    }
  }
}


