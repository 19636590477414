// Pixel colors
$whitePink: #fdeeff;
$slightlyPink: #fef8ff;
$fadedPink: #e966fc;
$pink: #EB40FF;

$whitePurple: #f7f2fA;
$purple: #8830A7;
$darkPurple: #642991;
$darkerPurple: #502584;

$success: #6ec151;
$lightError: #ffebf0;
$error: #fb4a4a;

$white: #ffffff;
$lightGrey: #F2F2F2;
$darkGrey: #59595C;

$whiteBlue: #e7f7ff;
$lightBlue: #d2f0ff;
$blue: #0AB2FC;
$brightBlue: #0bb2fd;
$google-blue: #4285F4;
$neonBlue: #2260fc;
$verdigrisBlue: #1A87B9;
$darkBlue: #165790;
$darkNavy: #000033;

// Additional Laboratory and Cost colors
$solid-black: #000000;
$solid-black-28: rgba(0, 0, 0, 0.28);
$veil: transparentize(#031F2C, 0.7);
$modalVeil: transparentize(#00020F, 0.13);
$blockVeil: transparentize(#191C45, 0.25);

$solid-dark-grey: #787878;
$solid-grey: #b8b8ba;
$placeholder-color: #b8b8ba;
$placeholder-color-light: #d1d1d1;
$placeholder-color-lighter: #dbdbdb;
$extra-light-grey: #f3f3f3;
$solid-light-grey: #f4f4f4;

$solid-light-blue: #e9f4fe;
$solid-light-blue-1: #f4fbff;
$solid-light-blue-2: #dff4fe;
$solid-light-blue-3: #bee9ff;
$solid-sky-blue: #d0efff;
$solid-blue: #26b3f9;
$solid-dark-blue: #191c45;

$solid-light-pink: #f7f2fA;
$solid-pink: #e550fb;

$solid-errors: #ff0c47;
$solid-green: #00b850;

:export {
  white: $white;
  pink: $pink;

  purple: $purple;
  darkPurple: $darkPurple;

  lightGrey: $lightGrey;
  darkGrey: $darkGrey;

  blue: $blue;
  darkBlue: $darkBlue;
  darkNavy: $darkNavy;

  solidBlue: $solid-blue;
  solidLightGray: $solid-light-grey;
}
