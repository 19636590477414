$Rubik-Font: 'Rubik';

/* cyrillic */
@font-face {
  font-family: $Rubik-Font;
  font-style: normal;
  font-weight: 300;
  src: local('Rubik'), local('Rubik-Light'), url('../fonts/rubik/Rubik-Light.ttf') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face {
  font-family: $Rubik-Font;
  font-style: normal;
  font-weight: 300;
  src: local('Rubik'), local('Rubik-Light'), url('../fonts/rubik/Rubik-Light.ttf') format('truetype');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
  font-family: $Rubik-Font;
  font-style: normal;
  font-weight: 300;
  src: local('Rubik'), local('Rubik-Light'), url('../fonts/rubik/Rubik-Light.ttf') format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: $Rubik-Font;
  font-style: normal;
  font-weight: 300;
  src: local('Rubik'), local('Rubik-Light'), url('../fonts/rubik/Rubik-Light.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* cyrillic */
@font-face {
  font-family: $Rubik-Font;
  font-style: normal;
  font-weight: 400;
  src: local('Rubik'), local('Rubik-Regular'), url('../fonts/rubik/Rubik-Regular.ttf') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face {
  font-family: $Rubik-Font;
  font-style: normal;
  font-weight: 400;
  src: local('Rubik'), local('Rubik-Regular'), url('../fonts/rubik/Rubik-Regular.ttf') format('truetype');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
  font-family: $Rubik-Font;
  font-style: normal;
  font-weight: 400;
  src: local('Rubik'), local('Rubik-Regular'), url('../fonts/rubik/Rubik-Regular.ttf') format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: $Rubik-Font;
  font-style: normal;
  font-weight: 400;
  src: local('Rubik'), local('Rubik-Regular'), url('../fonts/rubik/Rubik-Regular.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* cyrillic */
@font-face {
  font-family: $Rubik-Font;
  font-style: normal;
  font-weight: 500;
  src: local('Rubik Medium'), local('Rubik-Medium'), url('../fonts/rubik/Rubik-Medium.ttf') format('truetype');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face {
  font-family: $Rubik-Font;
  font-style: normal;
  font-weight: 500;
  src: local('Rubik Medium'), local('Rubik-Medium'), url('../fonts/rubik/Rubik-Medium.ttf') format('truetype');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
  font-family: $Rubik-Font;
  font-style: normal;
  font-weight: 500;
  src: local('Rubik Medium'), local('Rubik-Medium'), url('../fonts/rubik/Rubik-Medium.ttf') format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: $Rubik-Font;
  font-style: normal;
  font-weight: 500;
  src: local('Rubik Medium'), local('Rubik-Medium'), url('../fonts/rubik/Rubik-Medium.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* ZonaPro */
@font-face {
  font-family: 'ZonaPro-Regular';
  src: url('../fonts/ZonaPro/ZonaProRegular/ZonaPro-Regular.eot');
  src: local('ZonaPro-Regular'),
  url('../fonts/ZonaPro/ZonaProRegular/ZonaPro-Regular.eot') format('embedded-opentype'),
  url('../fonts/ZonaPro/ZonaProRegular/ZonaPro-Regular.woff') format('woff'),
  url('../fonts/ZonaPro/ZonaProRegular/ZonaPro-Regular.woff2') format('woff2'),
  url('../fonts/ZonaPro/ZonaProRegular/ZonaPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ZonaPro-Thin';
  src: url('../fonts/ZonaPro/ZonaProThin/ZonaPro-Thin.eot');
  src: local('ZonaPro-Thin'),
  url('../fonts/ZonaPro/ZonaProThin/ZonaPro-Thin.eot') format('embedded-opentype'),
  url('../fonts/ZonaPro/ZonaProThin/ZonaPro-Thin.woff') format('woff'),
  url('../fonts/ZonaPro/ZonaProThin/ZonaPro-Thin.woff2') format('woff2'),
  url('../fonts/ZonaPro/ZonaProThin/ZonaPro-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'ZonaPro-Bold';
  src: url('../fonts/ZonaPro/ZonaProBold/ZonaPro-Bold.eot');
  src: local('ZonaPro-Bold'),
  url('../fonts/ZonaPro/ZonaProBold/ZonaPro-Bold.eot') format('embedded-opentype'),
  url('../fonts/ZonaPro/ZonaProBold/ZonaPro-Bold.woff') format('woff'),
  url('../fonts/ZonaPro/ZonaProBold/ZonaPro-Bold.woff2') format('woff2'),
  url('../fonts/ZonaPro/ZonaProBold/ZonaPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ZonaPro-SemiBold';
  src: url('../fonts/ZonaPro/ZonaProSemiBold/ZonaPro-SemiBold.eot');
  src: local('ZonaPro-SemiBold'),
  url('../fonts/ZonaPro/ZonaProSemiBold/ZonaPro-SemiBold.eot') format('embedded-opentype'),
  url('../fonts/ZonaPro/ZonaProSemiBold/ZonaPro-SemiBold.woff') format('woff'),
  url('../fonts/ZonaPro/ZonaProSemiBold/ZonaPro-SemiBold.woff2') format('woff2'),
  url('../fonts/ZonaPro/ZonaProSemiBold/ZonaPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
