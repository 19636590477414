@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';
@import 'styles/colors.module';
@import 'styles/gradients.module';


.go-back-panel {

  &__btn {
    @include clickable-element;
    width: 89px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: $solid-pink;

    &:hover {
      background: $darkerPurpleToPurple;
    }
  }

  &__icon.MuiSvgIcon-root {
    font-size: 16px;
    color: $white;
  }

  &__text {
    font-size: 10px;
    line-height: 11px;
    font-family: $font-main-bold;
    text-transform: uppercase;
    color: $white;
  }
}
