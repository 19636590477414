@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';
@import 'styles/colors.module';


.button-with-checkbox {
  @include clickable-element;
  border: 0;
  outline: 0;
  height: 32px;
  padding: 0 14px 0 12px;
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: center;
  align-items: center;
  grid-column-gap: 6px;
  color: $solid-dark-grey;
  font-size: 10px;
  font-family: $font-main-bold;
  line-height: 11px;
  text-transform: uppercase;
  background-color: $extra-light-grey;
  white-space: nowrap;

  &.disabled {
    pointer-events: none;
    color: $placeholder-color-light;

    .custom-checkbox__icon {
      border: 1.5px $placeholder-color-light solid;
    }
  }

  .custom-checkbox__icon {
    height: 12px;
    width: 12px;
    border: 1.5px $brightBlue solid;
    border-radius: 2px;

    &.active {
      border-color: $brightBlue;
      background: $brightBlue;

      path {
        stroke: $white;
      }
    }
  }
}