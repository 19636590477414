
.general-messages-container {
  position: fixed;
  top: 24px;
  right: 50px;
  width: auto;
  height: auto;
  display: grid;
  justify-content: end;
  grid-gap: 16px;
  align-items: center;
  z-index: 1301;
}