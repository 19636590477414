@import '../../../styles/colors.module.scss';
@import '../../../styles/gradients.module.scss';

:local(.gameCheckboxContainer) {
  $checkboxSide: 90px;

  width: $checkboxSide;
  height: $checkboxSide;
  display: inline-block;
  background-position: center;
  background-size: cover;

  :global(.MuiCheckbox-root) {
    border: none;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: none;

    &:global(.Mui-checked) {
      background-image: $blueToPinkTransparent;
    }

    &:hover {
      background-color: transparent;
    }

    :global(.MuiIconButton-label) {
      width: 24px;
      height: 24px;

      path {
        fill: white;
      }
    }
  }
}
