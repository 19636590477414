@import 'styles/colors.module';
@import 'assets/styles/mixins';
@import 'assets/styles/variables.module';


.no-search-result {
  @include flex-centering;
  margin: 0;
  font-size: 10px;
  line-height: 11px;
  font-family: $font-main-bold;
  text-transform: uppercase;
  color: $placeholder-color-light;
  background-color: $white;

  &.transparent {
    background-color: transparent;
  }
}