@import 'styles/colors.module';
@import 'assets/styles/variables.module';


.asset-info-empty-state {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(3, auto);
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  grid-row-gap: 8px;

  &__icon {
    margin-bottom: 16px;
    width: 32px;
    height: 31px;
  }

  &__title,
  &__subtitle {
    margin: 0;
    color: $solid-dark-grey;
    text-align: center;
  }

  &__title {
    font-size: 12px;
    font-family: $font-main-bold;
    text-transform: uppercase;
  }

  &__subtitle {
    font-size: 10px;
    font-family: $font-main-regular;
  }
}