@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.file-list-item {
  $this: &;
  min-width: min-content;
  padding: 7px 24px;
  display: grid;
  grid-template-columns: minmax(32px, 0.32fr) minmax(68px, 0.68fr) minmax(47px, 0.47fr) minmax(293px, 2.93fr) minmax(106px, 1.06fr) minmax(333px, 3.33fr) minmax(374px, 3.74fr) minmax(148px, 1.48fr) minmax(71px, 0.71fr);
  align-items: center;
  grid-column-gap: 16px;
  background: $white;

  &.gameStorage {
    min-width: 900px;
    height: 64px;
    padding: 0 24px;
    grid-template-columns: minmax(32px, 0.32fr) minmax(68px, 0.68fr) minmax(47px, 0.47fr) minmax(293px, 2.93fr) minmax(97px, 0.97fr) minmax(148px, 1.48fr) minmax(71px, 0.71fr);
  }

  .custom-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;

    &__icon {
      width: 18px;
      height: 18px;
      border: 2px solid $brightBlue;

      &.active {
        background: $brightBlue;

        path {
          stroke: $white;
        }
      }
    }
  }

  &__preview {
    position: relative;
    width: 50px;
    height: 50px;
    overflow: hidden;

    .entity-preview.icon {
      width: 32px;
      height: 32px;
    }

    &:hover {
      #{$this}__info-icon-container {
        display: flex;
      }

      .entity-overlay {
        display: block;
      }
    }
  }

  &__video-play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    scale: 0.4;
  }

  &__info-icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    display: none;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: 1px solid $white;
    translate: -50% -50%;
    cursor: pointer;
    border-radius: 8px;
    transition: background 200ms;

    &:hover {
      background: transparentize($solid-black, 0.72);
    }
  }

  &__info-icon.MuiSvgIcon-root {
    fill: $white;
    font-size: 17px;
  }

  .entity-status {
    justify-self: start;
    margin-left: 12px;
  }

  &__name {
    @include text-primary;
  }

  &__extension,
  &__creation-date,
  &__size {
    @include text-primary;
    font-family: $font-main-regular;
  }

  &__game-name,
  &__jira-ticket {
    @include text-primary;
    color: $brightBlue;
    text-decoration: none;
  }

  &__game-name.clickable {
    cursor: pointer;
  }
}
