@import '../../../assets/styles/variables.module';
@import '../../../styles/colors.module';


.empty-state {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;

  &__logo {
    width: 48px;
    height: 48px;
  }

  &__text,
  &__none {
    margin: 0;
    text-align: center;
    color: $solid-dark-grey;
  }

  &__text {
    margin-top: 34px;
    font-size: 14px;
    font-family: $font-main-bold;
    text-transform: uppercase;
    line-height: 15px;
  }

  &__none {
    margin-top: 9px;
    font-size: 12px;
    font-family: $font-main-regular;
    line-height: 14px;
  }

  &__children {
    margin-top: 32px;
    text-align: center;
  }
}
