@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';
@import 'styles/colors.module';


.custom-textarea {
  $this: &;
  @include clickable-element;

  &:focus-within {
    #{$this}__inner {
      border: 1px solid $pink;
    }

    #{$this}__label {
      color: $pink;
    }
  }

  &.error {
    #{$this}__inner {
      border: 1px solid $error;
    }

    #{$this}__label {
      color: $error;
    }
  }

  &.disabled {
    pointer-events: none;

    #{$this}__inner {
      border: 1px solid transparent;
    }

    #{$this}__label {
      color: $placeholder-color-light;
    }

    #{$this}__field {
      color: $placeholder-color-lighter;
    }
  }

  &__inner {
    display: grid;
    grid-template-rows: auto auto;
    align-items: center;
    grid-row-gap: 4px;
    min-height: 81px;
    padding: 8px 16px;
    background-color: $white;
    border: 1px solid transparent;
  }

  &__header {
    height: 13px;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    grid-column-gap: 4px;
  }

  &__label,
  &__size {
    margin: 0;
    font-size: 12px;
    line-height: 13px;
    color: $solid-dark-grey;
  }

  &__label {
    @include text-ellipsis;
    font-family: $font-main-bold;
    text-transform: uppercase;
  }

  &__size {
    font-family: $font-main-regular;
  }

  &__field {
    border: none;
    outline: none;
    resize: none;
    min-height: 46px;
    font-size: 10px;
    font-family: $font-main-regular;
    color: $solid-dark-grey;

    &::placeholder {
      color: $placeholder-color-light;
    }

    &:disabled {
      background-color: $white;
    }
  }

  &__error {
    margin: 0;
    padding: 4px 16px;
    font-size: 10px;
    font-family: $font-main-regular;
    color: $error;
  }
}