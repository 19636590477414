@import 'styles/colors.module';
@import 'styles/gradients.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.collection-panel {
  height: 164px;
  background: $neonBlueToBrightBlue;

  &__body {
    display: grid;
    grid-template-columns: auto 1fr auto;
    justify-items: center;
    grid-column-gap: 16px;
    padding-right: 52px;
  }

  .search-box-rounded {
    margin-bottom: 24px;
    align-self: end;
  }

  &__upload-button {
    @include oval-button-primary(false, 152px, 40px, true);
    margin-bottom: 24px;
    align-self: end;
  }
}

.collection-panel-main-info {
  position: relative;
  padding: 18px 32px;
  width: 334px;
  height: 74px;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  align-content: center;
  align-items: center;
  grid-row-gap: 8px;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparentize($solid-dark-grey, 0.7);
    mix-blend-mode: multiply;
  }

  &__name {
    @include text-primary(14px);
    margin: 0;
    color: $white;
    isolation: isolate;
  }

  &__owner {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 10px;
    font-family: $font-main-regular;
    color: $white;
    isolation: isolate;
  }

  &__owner-avatar.MuiAvatar-root  {
    width: 16px;
    height: 16px;
  }

  &__owner-avatar.MuiAvatar-colorDefault {
    color: $white;
    background-color: $pink;
    font-size: 8px;
    font-family: $font-main-bold;
  }

  &__owner-name {
    @include text-primary(10px);
    font-family: $font-main-semiBold;
    color: $white;
  }
}
