@import '../../../styles/colors.module';
@import '../../../styles/gradients.module';
@import '../../../assets/styles/variables.module';
@import '../../../assets/styles/mixins';


.upload-progress {

  &__file {
    height: 48px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    justify-content: center;
    align-items: center;
    grid-gap: 18px;
    background: $white;
    border-bottom: 1px solid $solid-light-grey;
  }

  &__file-name {
    @include text-ellipsis;
    margin: 0;
    color: $solid-black;
    font-size: 14px;
    font-family: $font-main-regular;
  }

  &__file-type-icon.MuiSvgIcon-root {
    font-size: 16px;
    color: $solid-blue;
  }

  &__file-success-icon.MuiSvgIcon-root {
    font-size: 16px;
    color: $solid-pink;
  }

  &__file-fail-icon.MuiSvgIcon-root {
    font-size: 16px;
    color: $solid-errors;
  }

  &__circular.MuiCircularProgress-root {
    color: $placeholder-color;
    margin-top: 3px;

    .MuiCircularProgress-circle {
      stroke-width: 6px;
      r: 17px;
    }
  }
}
