@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.creative-general-info {
  position: relative;

  .preloader-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $white;
    z-index: 5;
  }

  &__author {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 8px;

    .MuiAvatar-root {
      width: 24px;
      height: 24px;
      font-size: 10px;
      font-family: $font-main-bold;
      text-transform: uppercase;
    }
  }

  &__author-name {
    @include text-ellipsis;
    color: $solid-blue;
    font-size: 10px;
    font-family: $font-main-bold;
    text-transform: uppercase;
    text-decoration: none;
  }

  &__field.link {
    .creative-general-info__field-value {
      @include clickable-element;
      color: $solid-blue;
    }
  }

  &__field-label,
  &__field-value {
    margin: 0;
    color: $solid-dark-blue;
    font-size: 12px;
    font-family: $font-main-bold;
    text-transform: uppercase;
  }

  &__field-label {
    margin-bottom: 8px;
  }

  &__field-value {
    @include text-ellipsis;
    height: 30px;
    padding: 6px 16px;
    color: $darkGrey;
    font-size: 10px;
    border: 1px solid $solid-light-grey;

    &.form {
      color: $placeholder-color;
    }
  }

  &__field-badges {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
}
