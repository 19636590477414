@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';
@import 'styles/colors.module';


.audio-player {
  padding: 0 16px;
  width: 100%;
  display: flex;
  height: 56px;
  background-color: $solid-black;

  &__controls-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto;
    justify-content: space-between;
    align-items: center;
    column-gap: 16px;
    z-index: 5;

    button .MuiSvgIcon-root {
      @include clickable-element;
      font-size: 40px;
      color: $white;
    }
  }

  &__controls,
  &__progress,
  &__volume {
    display: flex;
    align-items: center;
  }

  &__progress {
    width: 100%;
    column-gap: 16px;
  }

  &__progress-slider.MuiSlider-root {
    min-width: 100px;
  }

  &__progress-time {
    min-width: 120px;
    display: flex;
    justify-content: center;
    color: $white;
    font-size: 14px;
    font-family: $font-main-bold;
    white-space: nowrap;
  }

  &__volume-slider.MuiSlider-root {
    width: 50px;
  }
}


