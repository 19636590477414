@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.custom-multi-select {
  $this: &;
  @include clickable-element;
  @include flex-centering;
  position: relative;
  background-color: $white;
  height: 31px;

  &.active {
    #{$this}__field {
      border: 1px solid $solid-blue;
    }
  }

  &.disabled,
  &.pink.disabled {
    pointer-events: none;

    #{$this}__expand-icon {
      .MuiSvgIcon-root {
        color: $placeholder-color;
      }
    }

    #{$this}__label,
    #{$this}__field-title {
      color: $placeholder-color;
    }
  }

  &.error {
    #{$this}__field {
      border: 1px solid $solid-errors;
    }
  }

  // Start - Redesign multiselect in pink theme
  &.pink {

    &:hover:not(.active) {
      #{$this}__field {
        background-color: $slightlyPink;
      }
    }

    #{$this}__field-title {
      color: $pink;
    }

    #{$this}__expand-icon {
      .MuiSvgIcon-root {
        color: $pink;
      }
    }

    #{$this}__drop-down, {
      border: none;
    }

    .search__container {
      border-bottom: 1px solid $extra-light-grey;
    }

    #{$this}__options {
      @include sсroll-style(5px, true);
      margin: 8px 4px 8px 0;
      max-height: 32 * 6 + 5 + px;

      &.withSearch {
        max-height: 32 * 5 + px;
      }
    }

    .options-checkbox-item {
      height: 34px;
      margin: 0;
      padding: 0 18px;
      border: none;

      &:hover {
        .options-checkbox-item__name {
          color: $pink;
        }
      }

      .options-checkbox-item__label {
        border: 1.5px solid $pink;
      }

      .options-checkbox-item__name {
        color: $solid-dark-grey;
        text-transform: uppercase;
      }

      input:checked ~ .options-checkbox-item__label {
        border-color: $pink;
        background-color: $pink;
      }
    }
  }

  &.pink.active {
    #{$this}__field {
      border: 1px solid $pink;
    }
  }

  &.pink.error {
    #{$this}__field {
      border: 1px solid $solid-errors;
    }
  }
  // End - Redesign select in pink theme

  &__field {
    display: grid;
    justify-content: start;
    align-items: center;
    grid-template-columns: 1fr auto;
    background-color: $white;
    width: 100%;
    height: 100%;
    padding: 0 16px;
    text-align: left;
    text-transform: uppercase;
    font-family: $font-main-bold;
    font-size: 10px;
    border: 1px solid $solid-light-grey;

    &::placeholder {
      color: $darkGrey;
    }

    &.withClear {
      grid-template-columns: 1fr auto auto;
    }

    &.withLabel {
      grid-template-columns: auto 1fr auto;

      #{$this}__field-title {
        text-align: right;
      }
    }

    &.withLabel.withClear {
      grid-template-columns: auto 1fr auto auto;
    }
  }

  &__expand-icon {
    display: inline-block;

    .MuiSvgIcon-root {
      color: $solid-blue;
      transform: scale(0.45);
      margin: 3px -7px 0 auto;
    }

    &.error .MuiSvgIcon-root {
      color: $solid-errors;
    }
  }

  &__field-title {
    @include text-ellipsis;
    margin: 0;
    color: $solid-blue;
  }

  &__placeholder {
    color: $placeholder-color;
  }

  &__clean-value-icon.MuiSvgIcon-root {
    display: none;
    margin-left: 6px;
    color: $solid-dark-grey;
    font-size: 14px;

    &.active {
      display: block;
    }
  }

  &__label {
    @include clickable-element;
    @include text-ellipsis;
    margin-right: 8px;
    font-size: 10px;
    font-family: $font-main-bold;
    color: $solid-dark-grey;
    text-transform: uppercase;
  }

  &__drop-down {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: $white;
    transition: all 200ms ease-out;
    border: 1px solid $solid-light-grey;
    border-top-color: transparent;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
    z-index: 6;

    &.active {
      display: block;
    }

    &.top {
      top: initial;
      bottom: 100%;
      border: 1px solid $solid-light-grey;
      border-bottom-color: transparent;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &__options {
    @include sсroll-style(4px);
    overflow-y: auto;
    max-height: 30 * 6 + px;
    min-height: 50px;

    &.withSearch {
      max-height: 30 * 5 + px;
    }
  }

  // Redesign search - Start
  .search__container {
    padding: 0 16px;
    height: 34px;
    border-top: none;
    border-bottom: 1px solid $solid-light-grey;
  }

  .search__field {
    height: 20px;
  }

  .search__icon.MuiSvgIcon-root {
    color: $solid-blue;
    font-size: 18px;

    &:last-child {
      font-size: 16px;
      color: $solid-blue;
    }
  }
  // Redesign search - End

  .options-checkbox-item {
    margin: 0 16px;
    border-bottom: 1px solid $lightGrey;

    &__label {
      transform: scale(0.75);
    }
  }

  &__error-text {
    margin: 4px 0 0 0;
    padding: 0 16px;
    font-size: 10px;
    font-family: $font-main-regular;
    color: $solid-errors;
  }
}
