@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.creative-feedback {

  &__form {
    margin: 24px 0 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__form-top {
    display: flex;
    gap: 16px;
  }

  &__user-avatar.MuiAvatar-root  {
    width: 34px;
    height: 34px;
    font-size: 14px;
    font-family: $font-main-bold;
    text-transform: uppercase;
    background: $placeholder-color;
    color: $white;
  }

  &__textarea {

    .MuiInputBase-root {
      padding: 10px 16px;
      font-size: 12px;
      font-family: $font-main-regular;
      color: $darkGrey;
      border: 1px solid $solid-light-grey;

      &.Mui-focused {
        border: 1px solid $solid-blue;
      }

      &.Mui-error {
        border: 1px solid $solid-errors;
      }
    }

    .MuiInputBase-inputMultiline {
      &::placeholder {
        opacity: 1;
        color: $placeholder-color;
      }
    }

    .MuiFormHelperText-root {
      margin: 0 16px;
      color: $solid-errors;
    }
  }

  &__button {
    @include rectangle-button(true, 111px, null, true);
    margin-left: 50px;
  }

  &__no-comments {
    width: 100%;
    font-size: 14px;
    font-family: $font-main-regular;
    text-align: center;
    color: $darkGrey;
  }

  &__comments-list-title {
    margin: -8px 0 8px 0;
    font-size: 12px;
    font-family: $font-main-bold;
    color: $darkGrey;
    text-align: left;
    text-transform: uppercase;
  }

  &__comments-list {
    margin-top: 32px;
    display: grid;
    grid-template-rows: 1fr;
    grid-row-gap: 8px;
  }

  &__comment {
    padding: 16px 24px 24px;
    display: flex;
    gap: 16px;
    border: 1px solid $solid-light-grey;
  }

  &__comment-right {
    width: 100%;
  }

  &__comment-header {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
  }

  &__delete-icon-container {
    @include clickable-element;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $solid-light-grey;

    &:hover {
      background: transparent;
    }

    &:active {
      background: $solid-blue;

      .creative-feedback__delete-icon.MuiSvgIcon-root {
        color: $white;
      }
    }
  }

  &__delete-icon.MuiSvgIcon-root {
    font-size: 16px;
    color: $solid-dark-blue;
  }

  &__comment-main-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__comment-author-name,
  &__comment-date {
    margin: 0;
    font-size: 12px;
    font-family: $font-main-bold;
    color: $solid-dark-blue;
    text-transform: uppercase;
  }

  &__comment-date {
    font-size: 10px;
    color: $placeholder-color;
  }

  &__comment-body {
    padding-right: 32px;
  }

  &__comment-text {
    margin: 11px 0 0 0;
    font-size: 14px;
    font-family: $font-main-regular;
    color: $solid-dark-blue;
    white-space: break-spaces;
  }

  &__comment-link {
    display: inline-block;
    margin-top: 14px;
    font-size: 14px;
    font-family: $font-main-regular;
    color: $solid-blue;
    text-decoration: none;
  }

  &__comment-user-link {
    display: inline-block;
    margin-top: 14px;
    margin-right: 8px;
    padding: 6px;
    font-size: 10px;
    font-family: $font-main-bold;
    color: $white;
    background: $solid-blue;
    border-radius: 12px;
    text-transform: uppercase;
    text-decoration: none;
  }

  &__comment-media-link {
    margin-top: 18px;
    display: flex;
    align-items: center;
    gap: 9px;
    font-size: 12px;
    font-family: $font-main-bold;
    color: $solid-pink;
    text-transform: uppercase;
    text-decoration: none;
  }

  &__comment-media-icon.MuiSvgIcon-root {
    font-size: 20px;
  }
}