@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.creative-page {
  padding: 17px 54px 96px 56px;
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 32px;
}

.creative-line {
  border-bottom: 1px solid $solid-light-grey;
}

.creative-section-name {
  @include text-primary(14px);
  margin: 24px 0;
  color: $solid-dark-blue;
}

.creative-panel {
  display: flex;
  column-gap: 24px;
  justify-content: space-between;
}

.creative-navigation {
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .can-drop-wrapper.over {
    .creative-navigation__button {
      background: $solid-pink;
    }
  }

  &__button {
    @include text-primary(12px);
    @include clickable-element;
    width: auto;
    height: 40px;
    padding: 0 16px;
    display: grid;
    grid-template-columns: auto 30px;
    grid-column-gap: 8px;
    align-items: center;
    color: $solid-blue;
    transition: 200ms;
    background: $solid-light-grey;

    &.general {
      grid-template-columns: auto;
    }

    &:hover {
      color: $white;
      background: $solid-blue;
    }

    &:active, &.active {
      color: $white;
      background: $solid-blue;
    }

    &.disabled {
      pointer-events: none;
      color: $placeholder-color;
    }
  }

  .custom-menu {
    &__button {
      &:hover,
      &.active {
        .custom-menu__icon.MuiSvgIcon-root {
          fill: $white;
        }
      }
    }

    &__icon.MuiSvgIcon-root {
      fill: $darkGrey;
      font-size: 18px;
    }
  }
}
