:local(.closeButton):global(.MuiIconButton-root) {
  position: absolute;
  top: 0.35rem;
  right: 0.35rem;
  border-radius: 50%;
  opacity: 0.5;
  z-index: 200;

  svg {
    width: 1rem;
    height: 1rem;
  }
}
