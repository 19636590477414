@import 'assets/styles/mixins';


.not-found-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to top left, #151f24, #393f47);

  &.mob {
    align-items: center;
    justify-content: center;

    .not-found-page__description {
      width: 272px;
      margin-top: 66px;
      padding-left: 20px;

      p {
        color: #FFFFFF;
        font-family: 'Rubik';
        transform: scaleY(1.5);
        font-size: 10px;
        font-weight: 500;
        text-align: left;
      }
    }

    .not-found-page__logo {
      margin-top: 86px;
      width: 257px;
      height: 109px;

      img {
        width: 100%;
      }
    }

    .not-found-page__bottom-navigation {
      margin-top: 86px;

      .not-found-page__bottom-navigation__button {
        @include flex-centering;
        height: 48px;
        width: 272px;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 #000000;

        span {
          font-family: 'Rubik';
          font-size: 14px;
          font-weight: bold;
        }

        &.first {
          background-color: #27a2ce;

          span {
            color: #000000;
          }
        }

        &.second {
          margin-top: 8px;
          background-color: #162025;

          span {
            color: #27a2ce;
          }
        }

        &.third {
          margin-top: 8px;
          background-color: #bb0906;

          span {
            color: #ffffff;
          }
        }
      }
    }
  }
}
