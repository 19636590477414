@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';
@import 'styles/colors.module';


.doupload-button {
  @include text-primary(10px);
  @include clickable-element;
  overflow: unset;
  position: relative;
  padding: 0 12px;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  border: none;
  outline: none;
  width: 104px;
  height: 32px;
  background-color: $extra-light-grey;
  white-space: nowrap;

  &.pink {
    background-color: $whitePurple;
  }

  &:hover {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
  }

  &__name {
    margin: 0 12px 0 8px;
  }

  &__icon path {
    fill: $pink;
  }

  &__counter {
    @include flex-centering;
    position: absolute;
    padding: 0 5px;
    top: 0;
    right: 0;
    translate: 35% -35%;
    min-width: 21px;
    min-height: 21px;
    background-color: $pink;
    color: $white;
    border-radius: 50%;
  }
}
