@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.collection-storage-navigation {
  display: flex;
  align-items: center;

  &__separator {
    height: 15px;
    width: 1px;
    background-color: $placeholder-color-light;
  }

  &__path {
    @include text-primary(14px);
    text-decoration: none;

    &.pink {
      @include clickable-element;
      color: $pink;
    }
  }
}