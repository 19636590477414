$mainComponentsMargin: 1rem;

:local(.fieldsContainer) {
  display: flex;
  flex-wrap: wrap;

  :local(.field) {
    margin-bottom: $mainComponentsMargin;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    :global(.MuiAutocomplete-endAdornment) {
      top: calc(50% - 0.8em)
    }

    :global(.MuiTextField-root) {
      width: 300px;
    }
  }

  :local(.queryId) {
    width: 300px;
  }
}

:local(.filters) {
  margin-top: $mainComponentsMargin;
  margin-bottom: $mainComponentsMargin;

  :global(.MuiChip-deleteIcon) {
    margin: 0 0 0 5px;
  }

  :global(.MuiOutlinedInput-input) {
    padding: 0.5em 1.25em;
    line-height: 1.8;
  }

  :local(.andFilterAddButton) {
    margin-bottom: 0.5rem;
  }
}

:local(.checkboxes) {
  margin-bottom: $mainComponentsMargin;

  :global(.MuiFormControlLabel-root) {
    margin-left: 0;

    :global(.MuiCheckbox-root) {
      margin-right: 0.35rem;
    }
  }
}

:local(.buttons) {
  > :not(:last-child) {
    margin-right: 0.5rem;
  }
}
