@import 'assets/styles/mixins';

:local(.modal) {
  @include flex-centering;

  :local(.container) {
    position: relative;
    max-width: 80vw;
    min-width: 640px;
    max-height: 80vh;
    min-height: 320px;
    outline: none;
  }
}
