@import '../../styles/colors.module';
@import '../../styles/gradients.module';
@import '../../assets/styles/variables.module';
@import '../../assets/styles/mixins';


.upload-progress {
  position: fixed;
  bottom: 22px;
  right: 22px;
  width: 385px;
  height: auto;
  z-index: 1301;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  background: $white;

  &.hidden {
    display: none;
  }

  &__header {
    height: 56px;
    padding: 0 20px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: space-between;
    align-items: center;
    background: $pink-blue-gradient;
    color: $white;
  }

  &__header-button-container {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: center;
    align-items: center;
    grid-gap: 23px;
  }

  &__header-button.MuiSvgIcon-root {
    @include clickable-element;
    font-size: 16px;
  }

  &__header-text {
    margin: 0;
    font-size: 16px;
    font-family: $font-main-bold;
    text-transform: uppercase;
  }

  &__header-count {
    margin-left: 8px;
  }


  &__body {
    max-height: 0;
    transition: max-height 0.3s ease-out;

    &.expand {
      height: auto;
      max-height: 330px;

      .upload-progress__time {
        opacity: 1;
      }
    }
  }

  &__time {
    margin: 0;
    height: 32px;
    padding: 8px 24px;
    background: $solid-light-grey;
    color: $solid-dark-blue;
    font-size: 10px;
    font-family: $font-main-bold;
    text-transform: uppercase;
    opacity: 0;
  }

  &__files-container {
    @include sсroll-style;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 298px;
    padding: 0 20px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    background: $white;
  }
}
