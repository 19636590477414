@import 'styles/colors.module';

.can-drop-wrapper {
  &.over {
    background-color: $brightBlue;

    label {
      background-color: $brightBlue;
      color: $white;
    }

    button {
      color: $white;
    }
  }
}