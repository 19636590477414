@import 'styles/gradients.module';
@import 'assets/styles/mixins';


:local(.modal) {
  @include flex-centering;

  :local(.container) {
    $borderWidth: 0.625rem;

    position: relative;
    max-width: 80vw;
    border-top: $borderWidth solid;
    border-bottom: $borderWidth solid;
    border-image: $blueToPink 1;
    background-color: white;
    outline: none;
  }
}
