@import 'styles/gradients.module';


.entity-overlay {
  display: none;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: $blueToLightBlueWithTransparency;
  z-index: 3;

  &.active {
    display: block;
  }
}