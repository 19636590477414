@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';
@import 'styles/colors.module';


.create-or-edit-field-modal-content {
  padding: 32px 40px;
  width: 506px;
  min-height: 229px;

  &.withSubheader {
    min-height: 254px;
  }

  &.loading {
    display: grid;
    grid-template-rows: auto 1fr;
  }

  &__input-holder {
    margin: 24px auto 40px;
  }

  &__subheader {
    margin: 0 0 7px 0;
    font-size: 12px;
    font-family: $font-main-bold;
    text-transform: uppercase;
    color: $solid-dark-blue;
  }

  &__input {
    background-color: $white;
    width: 100%;
    height: 30px;
    padding: 0 16px;
    text-align: left;
    font-family: $font-main-bold;
    font-size: 10px;
    color: $darkGrey;
    border: 1px solid $solid-light-grey;
    outline: none;

    &:focus {
      border: 1px solid $solid-blue;
    }

    &.error {
      border: 1px solid $solid-errors;
    }

    &::placeholder {
      color: $placeholder-color;
      text-transform: uppercase;
    }
  }

  &__input-error {
    margin: 4px 0 0 0;
    padding: 0 16px;
    width: 100%;
    font-family: $font-main-regular;
    font-size: 10px;
    color: $solid-errors;
  }

  &__buttons-container {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: center;
    grid-gap: 16px;
  }

  &__button {
    @include rectangle-button(false, 112px, null, true);

    &:last-child {
      @include rectangle-button(true, 112px, null, true);
    }
  }
}
