@import '../../../styles/colors.module.scss';

:local(.dropzoneContainer) {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.2s;
  cursor: pointer;
  outline: none;

  :local(.placeholderIcon) {
    max-width: 100px;
    height: auto;
  }

  :local(.preview) {
    max-width: 100%;
    max-height: 100%;
  }

  :local(.error) {
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    font-family: 'Zona Pro';
    color: $error;
  }
}
