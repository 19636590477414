@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';
@import 'styles/colors.module';


.custom-menu {
  $this: &;

  &.pink {
    #{$this}__button {
      width: 32px;
      height: 32px;
      border-radius: 8px;

      #{$this}__icon.MuiSvgIcon-root {
        color: $pink;
        font-size: 16px;
      }

      &:hover {
        background-color: $whitePink;
      }

      &:active,
      &.active {
        background-color: $pink;

        #{$this}__icon.MuiSvgIcon-root {
          color: $white;
        }
      }
    }
  }

  &.disabled,
  &.disabled.pink {
    pointer-events: none;

    #{$this}__icon.MuiSvgIcon-root {
      color: $placeholder-color-light;
    }
  }

  &__button {
    position: relative;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover,
    &.active {
      background: $solid-blue;

      #{$this}__icon.MuiSvgIcon-root {
        color: $white
      }
    }
  }

  &__icon.MuiSvgIcon-root {
    color: $solid-dark-blue;
  }

  &__drop-down {
    .MuiList-root {
      padding: 14px 16px;
      min-width: 120px;
      max-width: 400px;
    }

    .MuiPaper-elevation8 {
      box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
    }

    &.pink {
      margin-top: 4px;

      .MuiMenu-paper {
        background: $solid-light-pink;
        border-radius: 8px;
      }

      #{$this}__drop-down-item.MuiMenuItem-root {
        &:hover {
          #{$this}__drop-down-icon {
            path {
              fill: $brightBlue;
            }
          }
        }
      }

      #{$this}__drop-down-text {
        color: $solid-dark-grey;
      }

      #{$this}__drop-down-icon {
        path {
          fill: $pink;
        }
      }
    }
  }

  &__drop-down-item.MuiMenuItem-root {
    @include clickable-element;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 0 !important;
    border: none;
    background: transparent!important;

    &:hover {
      #{$this}__drop-down-icon {
        path {
          fill: $pink;
        }
      }
    }
  }

  &__drop-down-icon {
    width: 22px;
    height: 22px;

    path {
      fill: $solid-blue;
    }

    &.MuiSvgIcon-root {
      font-size: 16px;
    }
  }

  &__drop-down-text {
    @include text-ellipsis();
    width: 100%;
    margin: 5px 0 0;
    padding-bottom: 4px;
    border-bottom: 1px solid $solid-light-grey;
    font-size: 10px;
    font-family: $font-main-bold;
    color: $darkGrey;
    text-transform: uppercase;
  }
}