@import './colors.module';

// Pixel gradients
$blueToPink: linear-gradient(90deg, $blue 0%, $pink 100%);
$blueToPinkTransparent: linear-gradient(90deg, transparentize($blue, 0.3) 0%, transparentize($pink, 0.3) 100%);
$darkerPurpleToPurple: linear-gradient(90deg, $darkerPurple 0%, $purple 100%);
$purpleToDarkNavyToLightBlue: linear-gradient($purple 0%, $darkNavy 50%, $verdigrisBlue 100%);
$darkBlueToLightBlue: linear-gradient(90deg, $darkBlue 0%, $verdigrisBlue 100%);
$neonBlueToBrightBlue: linear-gradient(180deg, $neonBlue 0%, $brightBlue 100%);
$blueToLightBlueWithTransparency: linear-gradient(180deg, transparentize($neonBlue, 0.5) 0%, transparentize($brightBlue, 0.5) 100%);

// Additional Laboratory and Cost gradients
$blue-pink-gradient: linear-gradient(90deg, #028bbf 0%, #ea40fd 100%);
$pink-blue-gradient: linear-gradient(270deg, #ea40fd, #028bbf);

:export {
  blueToPink: $blueToPink;
  blueToPinkTransparent: $blueToPinkTransparent;
  darkBlueToLightBlue: $darkBlueToLightBlue;
}
