@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.upload-path-selection {

  &__inputs-container {
    width: 426px;
    padding: 24px 16px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    background-color: $whitePurple;
  }

  &__buttons-container {
    margin-top: 32px;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    grid-column-gap: 16px;
  }

  &__button {
    @include oval-button-primary(true, 130px, 40px, true);

    &:last-child {
      @include oval-button-primary(false, 130px, 40px, true);
    }
  }
}