@import 'styles/gradients.module.scss';
@import 'styles/colors.module';
@import 'assets/styles/variables.module';


@mixin flex-centering {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin fit-image($objectFit: cover) {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: $objectFit;
}

@mixin clickable-element {
  cursor: pointer;
  user-select: none;
  // hide all highlight by tap
  -webkit-tap-highlight-color: transparent;
  // hide all outlines by tap and click
  outline: none;
}

@mixin button-element {
  @include clickable-element;
  display: block;
  border: none;
  transition: 150ms;
}

@mixin text-ellipsis($rows: null) {
  @if $rows {
    display: -webkit-box;
    -webkit-line-clamp: $rows;
    -webkit-box-orient: vertical;
    overflow: hidden;
  } @else {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

@mixin text-primary($fontSize: 12px, $rows: null) {
  @include text-ellipsis($rows);
  margin: 0;
  font-size: $fontSize;
  font-family: $font-main-bold;
  color: $solid-dark-grey;
  line-height: 13px;
  text-transform: uppercase;
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

@mixin rectangle-effect ($border-color) {
  position: relative;
  z-index: 1;
  box-sizing: border-box;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: 1px solid $border-color;
    z-index: -1;
    transition: 150ms;
  }
}

@mixin rectangle-button ($inverse: false, $width: null, $height: null, $icon: false) {
  @include button-element;
  @include rectangle-effect($border-color: $solid-pink);
  font-size: 12px;
  line-height: 1;
  font-family: $font-main-bold;
  text-transform: uppercase;
  letter-spacing: 0.3px;

  @if $width {
    width: $width;
    padding: 0;
  } @else {
    padding: 0 26px;
  }

  @if $height {
    height: $height;
  } @else {
    height: 40px;
  }

  @if $icon {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: center;
    grid-gap: 6px;

    @if $width {
      width: $width;
      padding: 0;
    } @else {
      padding: 0 16px;
    }
  }

  @if $inverse {
    color: $white;

    &:before {
      background-color: $solid-pink;
    }

  } @else {
    color: $solid-pink;

    &:before {
      background-color: $white;
    }
  }

  &:disabled {
    pointer-events: none;
    @if $inverse {
      color: $white;
      &:before {
        background: $placeholder-color;
        border: none;
      }
    } @else {
      color: $placeholder-color;
      &:before {
        background: $white;
        border: 1px solid $placeholder-color;
      }
    }
  }

  &:not(:disabled):hover {
    color: $white;

    &:before {
      background: $darkerPurpleToPurple;
      border: none;
    }
  }
}

@mixin rectangle-tab-button ($active: false, $width: null, $height: 40px) {
  @include button-element;
  @include rectangle-effect($border-color: transparent);
  font-size: 12px;
  line-height: 1;
  font-family: $font-main-bold;
  text-transform: uppercase;
  letter-spacing: 0.3px;

  @if $width {
    width: $width;
    padding: 0;
  } @else {
    padding: 0 26px;
  }

  @if $height {
    height: $height;
  }

  @if $active {
    color: $white;

    &:before {
      background-color: $solid-blue;
    }

  } @else {
    color: $solid-dark-blue;

    &:before {
      background-color: $solid-light-grey;
    }
  }
}

@mixin oval-button-primary ($outline: false, $width: null, $height: 40px, $icon: false) {
  @include clickable-element;
  @include flex-centering;
  font-size: 12px;
  font-family: $font-main-bold;
  line-height: 13px;
  text-transform: uppercase;
  color: $white;
  background-color: $pink;
  border-radius: 20px;
  padding: 0 30px;
  transition: 200ms;
  white-space: nowrap;

  .icon-inside-button,
  .icon-inside-button.MuiSvgIcon-root {
    color: $white;
    font-size: 16px;

    path {
      fill: $white;
    }

    @if $outline {
      color: $pink;

      path {
        fill: $pink;
      }
    }
  }

  @if $width {
    width: $width;
  }

  @if $height {
    height: $height;
  }

  @if $icon {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
  }

  @if $outline {
    color: $pink;
    background-color: transparent;
    border: 1px solid $pink;
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    color: $placeholder-color-light;
    background-color: $extra-light-grey;

    .icon-inside-button,
    .icon-inside-button.MuiSvgIcon-root {
      color: $placeholder-color-light;

      path {
        fill: $white;
      }

      @if $outline {
        color: $placeholder-color-light;

        path {
          fill: $placeholder-color-light;
        }
      }
    }

    @if $outline {
      color: $placeholder-color-light;
      background-color: transparent;
      border: 1px solid $extra-light-grey;
    }
  }

  &:hover {
    background-color: $fadedPink;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15);
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.3));

    @if $outline {
      color: $fadedPink;
      background-color: $whitePink;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
    }
  }

  &:active {
    background-color: $fadedPink;
    box-shadow: none;

    @if $outline {
      background-color: $whitePink;
      box-shadow: none;
    }
  }
}

@mixin oval-button-secondary ($blue: false, $width: null, $height: 40px, $icon: false) {
  @include clickable-element;
  @include flex-centering;
  font-size: 12px;
  font-family: $font-main-bold;
  line-height: 13px;
  text-transform: uppercase;
  color: $pink;
  background-color: $white;
  border-radius: 20px;
  padding: 0 30px;
  transition: 200ms;
  white-space: nowrap;

  .icon-inside-button,
  .icon-inside-button.MuiSvgIcon-root {
    color: $pink;
    font-size: 16px;

    path {
      fill: $pink;
    }

    @if $blue {
      color: $brightBlue;

      path {
        fill: $brightBlue;
      }
    }
  }

  @if $width {
    width: $width;
  }

  @if $height {
    height: $height;
  }

  @if $icon {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
  }

  @if $blue {
    color: $brightBlue;
    background-color: $whiteBlue;
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    color: $placeholder-color-light;
    background-color: $extra-light-grey;

    .icon-inside-button,
    .icon-inside-button.MuiSvgIcon-root {
      color: $placeholder-color-light;

      path {
        fill: $white;
      }
    }
  }

  &:hover {
    color: $fadedPink;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);

    @if $blue {
      color: $brightBlue;
    }
  }

  &:active {
    background-color: $whitePink;
    box-shadow: none;

    @if $blue {
      background-color: $lightBlue;
      box-shadow: none;
    }
  }
}

@mixin oval-button-tertiary ($width: null, $height: 32px) {
  @include clickable-element;
  @include flex-centering;
  font-size: 10px;
  font-family: $font-main-bold;
  line-height: 11px;
  text-transform: uppercase;
  color: $brightBlue;
  background-color: $extra-light-grey;
  border-radius: 20px;
  padding: 0 16px;
  transition: 200ms;
  white-space: nowrap;

  @if $width {
    width: $width;
  }

  @if $height {
    height: $height;
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    color: $placeholder-color-light;
    background-color: $extra-light-grey;
  }

  &:hover {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15);
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.3));
  }

  &:active,
  &.active {
    color: white;
    background-color: $brightBlue;
    box-shadow: none;
  }
}

@mixin oval-tab-button ($width: null, $height: 32px) {
  @include clickable-element;
  @include flex-centering;
  font-size: 10px;
  font-family: $font-main-bold;
  line-height: 11px;
  text-transform: uppercase;
  color: $solid-dark-grey;
  background-color: transparent;
  border: 1px solid $solid-dark-grey;
  border-radius: 20px;
  padding: 0 16px;
  transition: 200ms;
  white-space: nowrap;

  @if $width {
    width: $width;
    padding: 0;
  }

  @if $height {
    height: $height;
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    color: $placeholder-color-light;
    background-color: $extra-light-grey;
    border-color: $placeholder-color-light;
  }

  &:hover {
    background-color: $whitePurple;
  }

  &.active {
    color: $white;
    background-color: $pink;
    border-color: $pink;
  }
}

@mixin sсroll-style($width: 6px, $pinkTheme: false) {
  scroll-behavior: smooth;

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track {
    @if $pinkTheme {
      background: transparent;
      box-shadow: inset 0 0 10px #f7f2fA;
      border-radius: 5px;
      border-left: 2.5px solid transparent;
      border-right: 2.5px solid transparent;
    } @else {
      box-shadow: inset 0 0 $width $solid-light-grey;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-position: center;
    background-repeat: no-repeat;

    @if $pinkTheme {
      background-color: $pink;
      border-radius: $width / 2;
    } @else {
      background-color: $solid-blue;
    }
  }

  &::-webkit-resizer {
    background-repeat: no-repeat;
    width: $width;
    height: 0;
  }

  &::-webkit-scrollbar {
    width: $width;
    height: $width;
  }
}
