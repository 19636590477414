@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.uploader-config-state {
  $this: &;
  @include sсroll-style(5px, true);
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100% - 4px);
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: $whitePurple;
  border-radius: 0 0 8px 8px;

  &.nameError {
    padding-top: 0;
  }

  &__file-name-error-container {
    display: grid;
    grid-template-columns: auto 1fr;
    margin-bottom: 1px;

    #{$this}__error-icon-container {
      margin-right: 5px;
      width: 13px;
      height: 13px;
      border-radius: 100px;
    }

    #{$this}__error-icon.MuiSvgIcon-root  {
      color: $error;
      font-size: 8px;
    }
  }

  &__file-name-error {
    @include text-ellipsis(3);
    margin: 0;
    color: $error;
    font-size: 10px;
    font-family: $font-main-regular;
  }

  &__section-name {
    margin: 0 0 1px 0;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    color: $solid-dark-grey;
    height: 40px;
    padding: 0 16px;
    font-size: 12px;
    line-height: 13px;
    font-family: $font-main-bold;
    text-transform: uppercase;
    background-color: $white;

    & > span {
      @include text-ellipsis();
    }
  }

  &__error-icon-container {
    margin-right: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: $lightError;
    border-radius: 100px;
  }

  &__error-icon.MuiSvgIcon-root  {
    color: $error;
    font-size: 12px;
  }

  .search__container {
    margin-bottom: 1px;
  }
}