@import 'styles/colors.module';
@import 'assets/styles/mixins';
@import 'assets/styles/variables.module';


.asset-info-edit-state {
  padding: 8px 9px 15px 12px;

  &__inner-container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &__single-checkbox-container.options-checkbox-item.pink {
    height: 40px;

    .options-checkbox-item__name {
      font-size: 12px;
    }
  }

  &__field-name {
    @include text-primary(10px);
    display: flex;
    align-items: center;
    margin-bottom: 1px;
    padding: 0 16px;
    height: 34px;
    background-color: $white;

    &:not(:first-child) {
      margin-top: 1px;
    }
  }

  &__options-list {
    display: flex;
    flex-direction: column;
  }
}

.asset-info-edit-state-approve-field {
  height: 45px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  grid-column-gap: 16px;
  background-color: $white;

  &__name {
    @include text-primary(12px);
  }

  &__buttons-container {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: center;
    grid-column-gap: 4px;
  }

  &__button {
    @include oval-button-primary(true, 32px, 32px);
    padding: 0;
    border-radius: 8px;

    &.active {
      @include oval-button-primary(false, 32px, 32px);
      padding: 0;
      border-radius: 8px;
    }
  }
}
