@import 'styles/colors.module';
@import 'assets/styles/mixins';


.collection-assets-actions {
  $this: &;
  display: flex;

  &.hidden {
    display: none;
  }

  &__icon-container {
    @include flex-centering;
    @include clickable-element;
    width: 32px;
    height: 32px;
    background-color: $white;
    border-radius: 8px;
    transition: background-color 200ms;

    &:hover {
      background-color: $whitePink;
    }

    &.hidden {
      display: none;
    }
  }

  &__icon.MuiSvgIcon-root {
    color: $pink;
    font-size: 14px;
  }
}