@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.uploader-folder-card {
  width: 648px;
  padding-bottom: 16px;
  display: grid;
  grid-template-rows: auto auto;
  align-items: center;
  grid-row-gap: 8px;
  border-bottom: 1px solid $solid-light-grey;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 8px;
  }

  &__info {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    grid-column-gap: 8px;
  }

  &__path {
    @include text-primary(12px);
  }

  &__files-count {
    min-width: 24px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    font-size: 10px;
    font-family: $font-main-bold;
    line-height: 12px;
    color: $brightBlue;
    background-color: transparentize($brightBlue, 0.9);
    border-radius: 12px;
  }

  &__assets-list {
    display: grid;
    grid-template-columns: repeat(4, auto);
    justify-content: start;
    align-items: center;
    grid-gap: 16px;
  }
}