@import 'styles/gradients.module';
@import 'styles/colors.module';
@import 'assets/styles/mixins';
@import 'assets/styles/variables.module';


:local(.modal) {
  @include flex-centering;

  // change owerlay color
  & > div:first-child {
    background-color: $modalVeil!important;
  }

  :local(.container) {
    position: relative;
    max-width: 80vw;
    background-color: white;
    outline: none;

    &:before {
      content: '';
      display: block;
      height: 5px;
      width: 100%;
      background: linear-gradient(to left, #ea40fd, #028bbf);
    }

    &:after {
      content: '';
      display: block;
      height: 5px;
      width: 100%;
      background: linear-gradient(to left, #028bbf, #ea40fd);
    }
  }

  :local(.closeModalButton) {
    @include clickable-element;
    position: absolute;
    top: 12px;
    right: 8px;
    z-index: 5;
  }
}
