@import 'styles/colors.module';
@import 'assets/styles/mixins';


.entity-do-not-use-overlay {
  @include flex-centering;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  background-color: $blockVeil;
  z-index: 2;

  &__icon {
    color: $white;
  }
}