@import 'styles/colors.module';
@import 'assets/styles/mixins';


.asset-actions {
  $this: &;
  display: flex;

  &.blueTheme {
    gap: 8px;

    #{$this}__icon-container {
      @include oval-button-secondary(true, 40px, 40px);
      padding: 0;
      border-radius: 10px;

      &.hidden {
        display: none;
      }
    }

    #{$this}__icon.MuiSvgIcon-root {
      color: $brightBlue;
      font-size: 24px;
    }
  }

  &__icon-container {
    @include flex-centering;
    @include clickable-element;
    width: 32px;
    height: 32px;
    background-color: $white;
    border-radius: 8px;
    transition: background-color 200ms;

    &:hover {
      background-color: $whitePink;
    }

    &.hidden {
      display: none;
    }
  }

  &__icon.MuiSvgIcon-root {
    color: $pink;
    font-size: 14px;

    &.flipX {
     scale: -1 1;
    }
  }
}