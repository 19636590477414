@import '../../../styles/colors.module.scss';

:local(.modalContainer) {
  $verticalMargin: 0.75rem;
  $borderDelimiter: 1px solid $lightGrey;

  min-width: 400px;
  min-height: 200px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 2rem 2.25rem 0;
  position: relative;

  :local(.spinner) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  :local(.header) {
    border-bottom: $borderDelimiter;
    margin-bottom: $verticalMargin;

    :local(.publishDate) {
      margin-top: 0;
      font-size: 1rem;

      :local(.dateLabel) {
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }

  :local(.formContainer) {
    display: grid;
    grid-template-columns: 1fr 1.3fr;
    border: 1px solid $lightGrey;

    :local(.formFieldsContainer) {
      padding: 2rem 2.25rem;
      display: grid;
      gap: 1.25rem 0.75rem;
      grid-template-columns: 2fr 1fr 1fr;
      background-color: $lightGrey;

      :local(.disabled) {
        pointer-events: none;
        opacity: 0.5;
      }

      :local(.title) {
        grid-area: 1 / 1 / 2 / 2;
      }

      :local(.description) {
        grid-area: 1 / 2 / 2 / 4;
      }

      :local(.jiraProject) {
        grid-area: 2 / 1 / 3 / 2;
      }

      :local(.publishDate) {
        grid-area: 2 / 2 / 3 / 3;

        &:local(.spanned) {
          grid-column-end: 4;
        }

        :global(.MuiInputBase-root) {
          padding-right: 0;

          :local(.calendarIcon) {
            width: 1.25rem;
            height: auto;
          }
        }
      }

      :local(.ownerName) {
        grid-area: 2 / 3 / 3 / 4;
        overflow: hidden;
      }

      :local(.studio) {
        position: relative;
        grid-area: 3 / 1 / 3 / 2;
      }

      :local(.level) {
        position: relative;
        grid-area: 3 / 2 / 3 / 4;
      }

      :local(.pod) {
        position: relative;
        grid-area: 4 / 1 / 4 / 2;
      }

      :local(.editOption) input {
        padding-right: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      :local(.fieldActions) {
        position: absolute;
        top: 45px;
        right: 8px;
        display: flex;
        z-index: 2;

        &:local(.rightIndent) {
          right: 25px;
        }

        svg {
          color: $darkGrey;
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }

      :local(.categories) {
        grid-area: 5 / 1 / 5 / 4;
      }

      :local(.similarGames) {
        grid-area: 6 / 1 / 6 / 4;

        :local(.similarGamesList) {
          white-space: nowrap;
          overflow-x: auto;

          > :not(:last-child) {
            margin-right: 1rem;
          }
        }
      }

      :global(.MuiAutocomplete-clearIndicator) {
        display: none;
      }

      :global(.MuiTextField-root) {
        width: 100%;
      }
    }
  }

  :local(.footer) {
    border-top: $borderDelimiter;
    margin-top: $verticalMargin;
    padding-top: $verticalMargin;
    padding-bottom: $verticalMargin;

    display: flex;
    justify-content: flex-end;

    :global(.MuiButton-root):not(:last-child) {
      margin-right: 0.25rem;
    }
  }
}
