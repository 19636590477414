@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.collection-storage {
  margin: 0 50px 36px 24px;

  &__content.info {
    margin: 16px 0;
    min-height: 184px;
    height: calc(100vh - 302px);
  }

  &__add-assets-button {
    @include oval-button-primary(false, 135px, 40px);
    text-decoration: none;
  }

  &__results {
    margin-top: -5px;
  }
}

.collection-storage-panel {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  border-bottom: 1px solid $extra-light-grey;

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 16px;

    &.noGap {
      column-gap: 0;
    }
  }

  &__separator {
    width: 1px;
    height: 44px;
    background-color: $extra-light-grey;
  }

  &__create-folder-button {
    @include oval-button-secondary(true, 159px, 40px, true);
  }

  &__separator,
  &__create-folder-button {
    &.hidden {
      display: none;
    }
  }
}

.collection-storage-assets-panel {
  display: flex;
  justify-content: flex-end;
}