@import 'styles/colors.module';
@import 'assets/styles/mixins';
@import 'assets/styles/variables.module';


.custom-pagination {
  $this: &;
  @include flex-centering;
  margin-bottom: 8px;

  &.disabled {
    pointer-events: none;
  }

  &__arrow,
  &__page,
  &__dots {
    @include clickable-element;
    @include flex-centering;
    width: 32px;
    height: 32px;
    border-radius: 8px;

    &:hover {
      background-color: $extra-light-grey;
    }
  }

  &__arrow {
    &.disabled {
      pointer-events: none;

      #{$this}__arrow-icon.MuiSvgIcon-root {
        opacity: 0.4;
      }
    }
  }

  &__arrow-icon.MuiSvgIcon-root {
    font-size: 18px;
    color: $brightBlue;
  }

  &__pages {
    @include flex-centering;
  }

  &__page {
    @include text-primary(10px);
    color: $pink;

    &.active {
      background-color: $pink;
      color: $white;
    }
  }

  &__dots {
    position: relative;
    width: 22px;
    height: 22px;
    color: $brightBlue;

    &.flip:hover::after {
      rotate: 180deg;
      margin-top: 7px;
    }

    &:hover {
      background-color: $extra-light-grey;
      color: transparent;

      &::after {
        content: '\2039 \2039';
        margin-bottom: 2px;
        position: absolute;
        color: $brightBlue;
      }
    }
  }
}
