@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.creative-storage-navigation {
  padding: 16px 0;

  &__separator {
    width: 1px;
    height: 15px;
    background-color: $placeholder-color-light;

    &.hidden {
      display: none;
    }
  }

  &__path-button {
    @include text-primary;
    @include clickable-element;
    border: 0;
    outline: 0;
    height: 24px;

    &:disabled {
      pointer-events: none;
    }

    &.pink {
      color: $pink;
    }
  }
}
