@import 'styles/colors.module';
@import 'styles/gradients.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.download-assets {

  &__select-holder {
    margin-bottom: 32px;
    width: 426px;
    height: 80px;
    padding: 24px 16px;
    background-color: $whitePurple;
    border-radius: 8px;
  }

  &__buttons-container {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: center;
    grid-gap: 16px;
  }

  &__button {
    @include oval-button-primary(true, 130px, 40px, true);

    &:last-child {
      @include oval-button-primary(false, 130px, 40px, true);
    }
  }
}

