:local(.gameInfo) {
  margin-bottom: 1rem;
}

:local(.controls) {
  > :not(:last-child) {
    margin-right: 0.5rem;
  }
}

:local(.configKey) {
  word-break: break-all;
}
