:local(.fieldValue) {
  flex-grow: 1;
  background-color: white;
  padding: 1.25em 2em 1.25em 1.25em;
  font-size: 1rem;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
