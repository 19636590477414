@import 'assets/styles/mixins';


.uploader-assets-preview {
  @include sсroll-style(5px, true);
  margin-top: -2px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 665px;
  height: 392px;
  padding: 2px 12px 0 0;
  display: grid;
  align-items: start;
  grid-row-gap: 16px;
}