@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.fail-request-screen-container {
  @include flex-centering;
  height: 100%;
  flex-direction: column;
}

.fail-request-screen-icon {
  fill: $solid-dark-grey;
  width: 57px;
  height: 56px;
}

.fail-request-screen-text  {
  font-family: $font-main-regular;
  font-size: 14px;
  line-height: 1;
  color: $darkGrey;
  text-align: center;
  margin-top: 16px;

  &:first-letter{
    text-transform: capitalize
  }
}

