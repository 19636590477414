:local(.gameSelect),
:local(.itemsContainer) > :global(.MuiTextField-root) {
  margin-bottom: 0.5rem;
}

:local(.itemsContainer) {
  & > :global(.MuiTextField-root) {
    width: 500px;
  }

  :local(.itemList) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  :local(.item) {
    display: grid;
    grid-template-columns: 100px 300px 300px;
    gap: 1rem;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    :global(.MuiTextField-root),
    :global(.MuiInputBase-root) {
      width: 100%;
    }
  }

  :local(.sendItemsButton) {
    margin-bottom: 2rem;
  }
}
