@import 'styles/colors.module';
@import 'assets/styles/variables.module';


:local(.ownerName) {
  color: $pink;
  font-family: $font-main-bold;
  font-size: 1rem;
  text-decoration: underline;
  cursor: pointer;
}
