@import 'styles/colors.module';
@import 'assets/styles/mixins';
@import 'assets/styles/variables.module';


.options-checkbox-item {
  $this: &;
  @include clickable-element;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 6px;
  align-items: center;
  height: 30px;
  background-color: $white;

  &:not(:last-child) {
    border-bottom: 1px solid $solid-light-grey;
  }

  &.endPart {
    grid-template-columns: auto 1fr auto;
  }

  &.disabled {
    pointer-events: none;

    #{$this}__label {
      opacity: 0.5;
    }

    #{$this}__name {
      color: $placeholder-color;
    }
  }

  &.pink {
    height: 34px;
    padding: 0 16px;
    border: none;

    #{$this}__label {
      border: 1.5px solid $pink;
      scale: 0.75;
    }

    #{$this}__name {
      color: $solid-dark-grey;
      text-transform: initial;
    }

    input:checked ~ #{$this}__label {
      border-color: $pink;
      background-color: $pink;
    }
  }

  input {
    display: none;
  }

  &__label {
    position: relative;
    width: 16px;
    height: 16px;
    border: 1px solid $darkGrey;
    border-radius: 2px;

    &:before {
      content: '';
      position: absolute;
      top: -5px;
      left: 0;
      transform: rotate(45deg);
      width: 7px;
      transform-origin: left bottom;
      height: 12px;
      border-bottom: 2px solid $white;
      border-right: 2px solid $white;
    }
  }

  &__radio-label {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 13.5px;
    height: 13.5px;
    border: 1px solid $pink;
    border-radius: 50%;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 7px;
      height: 7px;
      translate: -50% -50%;
      background-color: $white;
      border-radius: 50%;
    }
  }

  &__name {
    @include text-ellipsis;
    color: $darkGrey;
    font-family: $font-main-bold;
    font-size: 10px;
    text-transform: uppercase;
  }

  input:checked ~ #{$this}__label {
    border-color: $solid-blue;
    background-color: $solid-blue;
  }

  input:checked ~ #{$this}__radio-label {
    &:before {
      border-color: $pink;
      background-color: $pink;
    }
  }
}
