:local(.playerContainer) {
  position: relative;
  width: inherit;
  max-width: inherit;
  min-width: inherit;
  height: inherit;
  max-height: inherit;
  min-height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    :local(.controlsContainer) {
      transform: translateY(0);
    }
  }

  :local(.playerClickWrapper) {
    width: inherit;
    max-width: inherit;
    min-width: inherit;
    height: inherit;
    max-height: inherit;
    min-height: inherit;

    video {
      width: inherit !important;
      max-width: inherit;
      min-width: inherit;
      max-height: inherit;
      min-height: inherit;
    }
  }

  :local(.controlsContainer) {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    transition: transform 200ms ease-in-out;
    transform: translateY(101%);
    z-index: 5;

    :global(.MuiIconButton-root) {
      padding: 0;

      &:hover {
        background-color: unset;
      }
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;

      path {
        fill: white;
      }
    }

    :local(.startStopButton) {
      margin-right: 1.5rem;
    }

    :local(.timelineSlider) {
      margin-right: 0.75rem;
      flex-grow: 1;
    }

    :local(.timeDisplay) {
      color: white;
      font-weight: bold;
      white-space: nowrap;
      margin-right: 2rem;
    }

    :local(.soundIcon) {
      margin-right: 1rem;
      width: 3rem;
      transform: scale(1.5);
    }

    :local(.volumeSlider) {
      margin-right: 1.5rem;
      flex-basis: 15%;
    }
  }
}

