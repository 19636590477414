@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.custom-badge {
  height: 30px;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 0 8px 0 10px;
  background: $pink;
  border-radius: 20px;
  word-break: keep-all;
  white-space: nowrap;

  &.disabled {
    pointer-events: none;
  }

  &__name {
    font-size: 10px;
    font-family: $font-main-bold;
    line-height: 11px;
    color: $white;
    text-transform: uppercase;
  }

  &__icon.MuiSvgIcon-root {
    @include clickable-element;
    font-size: 14px;
    // no variable because used only here
    color: #980ab1;

    &:hover {
      color: darken(#980ab1, 10%);
    }
  }
}
