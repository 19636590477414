@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';
@import 'styles/colors.module';


.confirm-round-modal {

  &__content {

    &.loading {
      display: grid;
      grid-template-rows: auto 1fr;
    }
  }

  &__text {
    margin: -8px 0 32px;
    font-size: 14px;
    font-family: $font-main-regular;
    color: $solid-dark-grey;
    word-break: break-word;

    span.bold {
      font-family: $font-main-bold;
    }
  }

  &__buttons-container {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: center;
    grid-gap: 16px;
  }

  &__button {
    @include oval-button-primary(true, 130px, 40px, true);

    &:last-child {
      @include oval-button-primary(false, 130px, 40px, true);
    }
  }
}



