@import 'assets/styles/variables.module';
@import 'styles/colors.module';
@import 'assets/styles/mixins';


.search-box-rounded {
  position: relative;
  width: 100%;
  height: 40px;
  padding-left: 16px;
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-content: center;
  align-items: center;
  grid-column-gap: 11px;
  outline: 1px solid $white;
  border-radius: 20px;


  &__input,
  .sui-search-box__text-input, {
    border: none;
    outline: none;
    padding: 0;
    width: 100%;
    height: 38px;
    font-size: 12px;
    font-family: $font-main-bold;
    line-height: 13px;
    text-transform: uppercase;
    color: $white;
    background-color: transparent;
    box-shadow: none;

    &::placeholder {
      color: $white;
      text-transform: initial;
      font-family: $font-main-regular;
    }
  }

  .sui-search-box {
    &__submit {
      display: none;
    }

    &__autocomplete-container {
      position: absolute;
      width: 320px;
      padding: 0;
      top: calc(100% + 4px);
      left: 0;
      visibility: visible;
      background-color: $whitePurple;
      border: 1px solid $white;
      box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      z-index: 20;
    }

    &__suggestion-list {
      margin: 8px 0;
      padding: 0;
    }

    &__suggestion-list li {
      @include clickable-element;
      height: 40px;
      margin: 0;
      padding: 0 17px;
      display: flex;
      align-items: center;
      font-size: 10px;
      font-family: $font-main-bold;
      line-height: 11px;
      color: $solid-dark-grey;
      text-transform: uppercase;
      background-color: transparent;

      span {
        @include text-ellipsis();
      }

      &:hover {
        color: $pink;
      }
    }
  }

  &__clear-icon.MuiSvgIcon-root,
  &__search-icon.MuiSvgIcon-root {
    @include clickable-element;
    font-size: 16px;
    color: $white;
  }

  &__clear-icon.MuiSvgIcon-root {
    visibility: hidden;

    &.active {
      visibility: visible;
    }
  }

  &__search-icon-container {
    @include clickable-element;
    width: 44px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    background-color: transparentize($white, 0.7);
    border-radius: 0 20px 20px 0;
  }
}