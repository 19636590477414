@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.button-with-dropDown {
  $this: &;
  position: relative;
  display: inline-block;
  width: max-content;

  &.active {
    #{$this}__trigger {
      background-color: $brightBlue;
    }

    #{$this}__name {
      color: $white;
    }

    #{$this}__icon.MuiSvgIcon-root {
      color: $white;
    }

    #{$this}__content {
      display: block;
    }
  }

  &__trigger {
    @include clickable-element;
    height: 40px;
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 4px;
    justify-content: center;
    align-items: center;
    padding: 0 10px 0 16px;
    background: $white;
    white-space: nowrap;
    word-break: keep-all;
    transition: all 200ms;
  }

  &__name {
    margin: 0;
    font-size: 12px;
    font-family: $font-main-bold;
    line-height: 13px;
    text-transform: uppercase;
    color: $brightBlue;
  }

  &__icon.MuiSvgIcon-root {
    margin-top: 2px;
    color: $brightBlue;
    font-size: 10px;
  }

  &__content {
    display: none;
    position: absolute;
    top: 100%;
    background: $whitePurple;
    border: 1px solid $white;
    border-radius: 8px;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
    z-index: 5;
  }
}
