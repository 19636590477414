@import 'styles/colors.module';
@import 'assets/styles/mixins';


.custom-checkbox {
  @include clickable-element;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &__mui-icon.MuiSvgIcon-root {
    margin: -3px;
    color: white;
    font-size: 24px;
  }

  &__input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  &__icon {
    display: inline-block;
    height: 15px;
    width: 15px;
    background: transparent;
    border: 1.5px $white solid;
    border-radius: 2px;

    &.active {
      border-color: $white;
      background: $white;

      path {
        stroke: $solid-pink;
      }
    }
  }
}