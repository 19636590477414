@import 'styles/colors.module';
@import 'assets/styles/mixins';
@import 'assets/styles/variables.module';


.uploader-asset-info {
  width: 328px;

  &__header {
    height: 63px;
    padding: 16px;
    display: grid;
    grid-template-rows: auto auto;
    align-content: space-between;
    background-color: $whitePurple;
    border-bottom: 1px solid $white;
    border-radius: 8px 8px 0 0;
  }

  &__title,
  &__subtitle {
    @include text-primary(14px);
  }

  &__subtitle {
    font-size: 10px;
    font-family: $font-main-regular;
    text-transform: initial;
  }

  &__body {
    height: 329px;
    padding-top: 8px;
    background-color: $whitePurple;
    border-radius: 0 0 8px 8px;
  }
}