@import './fonts';


// dropDown list
$list-max-height: 240px;
$dropdown-max-height: 179px;

// fonts
$font-main-regular: 'ZonaPro-Regular';
$font-main-thin: 'ZonaPro-Thin';
$font-main-bold: 'ZonaPro-Bold';
$font-main-semiBold: 'ZonaPro-SemiBold';

// canvas
$canvas-width-default: 815;
$canvas-width-render-landscape: 1920;
$canvas-width-render-square: 1080;
$square-width-of-blur-side: (1 - 1080 / 1920) / 2 * 100%;
$portrait-width-of-blur-side: (1920 - 1080 * 1080 / 1920) / 2 * 100% / 1920;
$four-on-five-width-of-blur-side: (1920 - 1080 * 1080 / 1350) / 2 * 100% / 1920;

:export {
  canvasWidthDefault: $canvas-width-default;
  canvasWidthRenderLandscape: $canvas-width-render-landscape;
  canvasWidthRenderSquare: $canvas-width-render-square;
  squareWidthOfBlurSide: $square-width-of-blur-side;
  portraitWidthOfBlurSide: $portrait-width-of-blur-side;
  fourOnFiveWidthOfBlurSide: $four-on-five-width-of-blur-side;
}
