@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.creative-head {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  grid-column-gap: 24px;

  &__media-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 207px;
    height: 118px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.icon {
      transform: scale(0.6);
      object-fit: contain;
    }
  }

  &__video-play-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }
}

.creative-head-info {
  &__title,
  &__game-link {
    margin: 0;
    font-family: $font-main-bold;
    text-transform: uppercase;
  }

  &__title {
    font-size: 16px;
    color: $darkGrey;
  }

  &__game-link {
    @include clickable-element;
    margin-top: 8px;
    font-size: 14px;
    color: $solid-blue;
  }

  &__other {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 32px;
    row-gap: 8px;
  }

  &__field {
    font-size: 12px;
    color: $darkGrey;
    font-family: $font-main-regular;

    span:first-of-type {
      font-family: $font-main-bold;
    }
  }
}

.creative-head-actions {
  margin-top: 3px;
  align-self: start;
  display: flex;
  column-gap: 8px;

  &__button {
    @include clickable-element;
    @include flex-centering;
    min-width: 40px;
    height: 40px;
    font-size: 10px;
    color: $solid-blue;
    font-family: $font-main-bold;
    text-transform: uppercase;
    border: 1px solid $solid-blue;

    &--with-text {
      padding: 0 16px;
      column-gap: 4px;
    }

    &:hover {
      color: $white;
      background: $solid-blue;
      .creative-head-actions__icon.MuiSvgIcon-root {
        color: $white;
      }
    }
  }

  &__icon.MuiSvgIcon-root {
    color: $solid-blue;
    font-size: 24px;
    &.small {
      font-size: 16px;
    }
  }
}

