@import 'styles/colors.module';
@import 'assets/styles/mixins';


.accordion-panel {

  &.hidden {
    display: none;
  }

  .MuiAccordion-root {
    box-shadow: none;
    border: none;
  }

  .MuiAccordionSummary-root {
    padding: 0 16px;
    background-color: $white;
    height: 40px;
    min-height: 40px;
    border: 1px solid $white;

    &:hover {
      background-image: none;

      .MuiAccordionSummary-expandIcon path {
        fill: $pink;
      }
    }

    &.Mui-expanded {
      border-color: $pink;

      .MuiAccordionSummary-expandIcon {
        rotate: 180deg;
      }
    }
  }

  .MuiAccordionSummary-content {
    @include text-primary(12px);
  }

  .MuiTouchRipple-root {
    display: none;
  }

  .MuiAccordionSummary-expandIcon {
    rotate: 90deg;
    scale: 0.7;

    path {
      fill: $pink;
    }
  }

  .MuiAccordionDetails-root {
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: $whitePurple;
  }
}


