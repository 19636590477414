@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.preloader-container {
  @include flex-centering;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.preloader-icon {
  animation: 1000ms linear infinite spin_preloader;
}

.preloader-text  {
  font-family: $font-main-regular;
  color: $placeholder-color;
  text-align: center;
  margin-top: 8px;
}

@keyframes spin_preloader { from { transform: rotate(0); } to { transform: rotate(360deg); } }
