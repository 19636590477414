@import '../../../styles/colors.module';
@import '../../../assets/styles/variables.module';
@import '../../../assets/styles/mixins';


.google-login {
  @include clickable-element;
  margin-top: 24px;
  width: 256px;
  height: 46px;
  display: flex;
  align-items: center;
  background: $google-blue;
  text-decoration: none;

  &__image {
    object-fit: contain;
  }

  &__text {
    margin: 0 13px;
    font-size: 16px;
    font-family: $font-main-bold;
    color: $white;
    text-transform: uppercase;
    text-align: center;
  }
}