@import '../../../styles/colors.module.scss';
@import '../../../styles/gradients.module.scss';

:local(.leftSidebar) {
  display: flex;
  flex-shrink: 0;
  position: sticky;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100vh;
  overflow-y: auto;
  flex-direction: column;

  background-image: $purpleToDarkNavyToLightBlue;

  :local(.tiltingPointLogo) {
    cursor: pointer;
  }

  :local(.tiltingPointLogo),
  :local(.iconButton) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  :local(.tiltingPointLogo),
  :local(.iconButton.profileIcon) {
    padding: 1rem;

    svg,
    :global(.MuiAvatar-root) {
      $sideSize: 2rem;

      width: $sideSize;
      height: $sideSize;
    }
  }

  :local(.iconButton) {
    $svgSideSize: 1.25rem;

    padding: 1.25rem;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &.flip {
      transform: scaleX(-1);
    }

    svg {
      width: $svgSideSize;
      height: $svgSideSize;

      path,
      circle {
        fill: white;
      }
    }

    &:global(.MuiIconButton-root) {
      border-radius: 0;
    }

    &:hover,
    &:local(.active),
    &:global(.MuiIconButton-root):hover {
      background-color: white;

      path,
      circle {
        fill: $pink;
      }
    }

    :global(.MuiCircularProgress-root) {
      width: $svgSideSize !important;
      height: $svgSideSize !important;
      color: white;
    }
  }

  :local(.bottomItems) {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-self: flex-end;
  }
}

:local(.drawerContent) {
  overflow-y: auto;
}

:local(.menu) {
  :global(.MuiMenuItem-root) {
    &:hover:not(:global(.Mui-selected)) {
      background-color: white;
      color: $pink;

      .menuItemIcon path {
        fill: $pink;
      }
    }
  }
}

:local(.menuItemIcon) {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

:local(.creativeApiKeyContainer) {
  padding: 2rem 3rem;
  text-align: center;

  :local(.keyInfo) {
    font-weight: bold;
  }

  :local(.keyContainer) {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    :local(.key) {
      margin-right: 0.5em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
