:local(.form) {
  margin-bottom: 1rem;
}

:local(.fieldsContainer) {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  :local(.field):not(:last-child) {
    margin-right: 0.5rem;
  }

  :local(.queryId) {
    width: 300px;
  }
}
