@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.collection-storage-grid-view {

  &__section {
    display: grid;
    grid-template-rows: auto auto;
    align-items: center;
    grid-row-gap: 22px;
  }

  &__title {
    @include text-primary;
  }

  &__folders,
  &__files {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__separator {
    display: none;
    margin: 24px 0;
    width: 100%;
    height: 1px;
    background-color: $extra-light-grey;

    &.active {
      display: block;
    }
  }
}