.creative-drive-link {
  display: flex;
  align-items: center;
  column-gap: 16px;

  .custom-input {
    width: 250px;
  }

  &__icon svg {
    width: 30px;
    height: 30px;
  }
}
