@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';
@import 'styles/colors.module';


.confirm-modal-content {
  padding: 32px 40px;
  width: 506px;
  min-height: 221px;

  &.loading {
    display: grid;
    grid-template-rows: auto 1fr;
  }

  &__text {
    margin: 24px 0 40px;
    font-size: 14px;
    font-family: $font-main-regular;
    color: $solid-dark-blue;
    word-break: break-word;

    span.bold {
      font-family: $font-main-bold;
    }
  }

  &__buttons-container {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: center;
    grid-gap: 16px;
  }

  &__button {
    @include rectangle-button(false, 112px, null, true);

    &:last-child {
      @include rectangle-button(true, 112px, null, true);
    }
  }
}
