@import 'styles/colors.module';
@import 'assets/styles/mixins';
@import 'assets/styles/variables.module';


.asset-info-static {
  $this: &;
  padding: 0 9px 12px 0;

  .asset-info-static-block {
    min-height: 40px;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 16px;

    &__name,
    &__value {
      margin: 0;
      font-size: 12px;
      font-family: $font-main-bold;
      color: $solid-dark-grey;
      text-transform: uppercase;
    }

    &__value {
      font-size: 10px;
      font-family: $font-main-semiBold;
      &--icons {
        display: flex;
        column-gap: 12px;
        align-items: center;
      }
    }

    &__link-icon {
      width: 16px;
      height: 16px;
    }

    &__icon {
      @include clickable-element;
      &:hover path {
        stroke: $pink;
      }
      &:active {
        scale: 0.8;
      }
      path {
        stroke: $solid-dark-grey;
      }
    }
  }

  .asset-info-static-accordion {
     .MuiAccordionSummary-root,
     .MuiAccordionSummary-root.Mui-expanded {
       border-color: $whitePurple;
       background-color: $whitePurple;
     }
    &__content {
      padding: 0 16px 12px;
    }
  }

  &__field {
    display: flex;
    justify-content: space-between;
    column-gap: 16px;
    &:not(:first-of-type) {
      margin-top: 16px;
    }
  }

  &__sub-header {
    margin: 0;
    font-size: 10px;
    font-family: $font-main-semiBold;
    color: $solid-dark-grey;
    text-transform: uppercase;
  }

  &__list {
    list-style: none;
    align-self: center;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    &.empty {
      overflow: hidden;
    }

    &.withLink {
      margin-bottom: 9px;
    }
  }

  &__list-item-text {
    margin: 0;
    font-size: 10px;
    font-family: $font-main-regular;
    color: $solid-dark-grey;
    text-transform: uppercase;
  }

  &__no-data-text {
    @include text-primary(10px);
    font-family: $font-main-regular;
    color: $placeholder-color-light;
  }

  &__list-item::marker {
    color: $pink;
  }

  &__jira-ticket-link {
    font-size: 10px;
    font-family: $font-main-semiBold;
    text-transform: uppercase;
    text-decoration: none;
    color: $pink;
  }
}
