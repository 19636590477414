@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';
@import 'styles/colors.module';


.creative-sections-menu {
  $this: &;

  &__button {
    @include flex-centering;
    position: relative;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background: $solid-light-grey;

    &:hover {
      #{$this}__icon.MuiSvgIcon-root {
        color: $solid-pink;
      }
    }

    &.active {
      background: $solid-blue;

      #{$this}__icon.MuiSvgIcon-root {
        color: $white;
      }
    }
  }

  &__icon.MuiSvgIcon-root {
    color: $solid-blue;
  }

  &__drop-down {
    .MuiList-root {
      padding: 8px 16px;
      width: 146px;
    }

    .MuiPaper-elevation8 {
      box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
    }
  }

  &__drop-down-item.MuiMenuItem-root {
    @include clickable-element;
    padding: 0 !important;
    border: none;
    background: transparent!important;

    &:not(:last-child) {
      border-bottom: 1px solid $solid-light-grey;
    }

    &:hover,
    &.active {
      #{$this}__drop-down-text {
        color: $solid-pink;
      }
    }
  }

  &__drop-down-text {
    @include text-primary(12px);
    width: 100%;
    padding: 13px 0;
    color: $solid-dark-blue;
  }
}
